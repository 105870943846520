import { forwardRef } from 'react';
import Slider from '@mui/material/Slider';

function calculateValue(value: number) {
    return Math.round(Math.pow(10, value));
}

export type LogarithmicSliderProps = {
    className?: string,
    valueLabelDisplay: 'on' | 'auto' | 'off',
    marks: Array<{ value: number, label: string }>,
    min: number,
    max: number,
    allowOverflow?: boolean,
    onBlur: () => void,
    onChange: (value: any) => void,
    value: string,
}

const LogarithmicSlider = forwardRef<HTMLSpanElement, LogarithmicSliderProps>(({ valueLabelDisplay, marks, min, max, allowOverflow, className, onBlur, onChange, value }, ref) => {

    const handleChange = (e: Event, value: number | number[], activeThumb: number) => {
        if (value > max) {
            const maxValue = calculateValue(max);
            onChange(`${maxValue}+`);
            return;
        }
        onChange(`${calculateValue(value as number)}`)
    }

    function valueLabelFormat(value: number) {
        const maxValue = calculateValue(max);
        return value <= maxValue ? value.toFixed() : `${maxValue}+`;
    }

    return (
        <Slider
            className={className}
            ref={ref}
            value={value == null ?
                0 :
                isNaN(Number(value)) ?
                    max + 0.1 :
                    Math.log10(Number.parseInt(value))}
            min={min}
            step={0.001}
            max={allowOverflow ? max + 0.1 : max}
            marks={marks}
            scale={calculateValue}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            onChange={handleChange}
            onBlur={onBlur}
            valueLabelDisplay={valueLabelDisplay}
            aria-labelledby="non-linear-slider"
        />
    );
});
export default LogarithmicSlider