import { useEffect, useState } from "react";
import { getLoggedInUser } from "../utils";

import { useAuthenticationRedirect, useHotJarStateChange } from "../CustomHooks";
import { SECTIONS } from "../Constants";
import { Home } from ".";

enum UserState {
  New,
  InProgress,
  Complete,
}

export default function LandingPage(): JSX.Element {
  useAuthenticationRedirect();
  const [user] = useState(getLoggedInUser());
  const [userState, setUserState] = useState<UserState>();
  useHotJarStateChange();
  useEffect(() => {
    if (!user?.sectionAnswers || user.sectionAnswers.length === 0) {
      setUserState(UserState.New)
      return;
    } else if (user.sectionAnswers.length < SECTIONS.length) {
      setUserState(UserState.InProgress)
      return;
    } else {
      setUserState(UserState.Complete);
    }
    
  },[user?.sectionAnswers]);

  switch (userState) {
    case UserState.New: return <Home />
    case UserState.InProgress: return <Home />
    case UserState.Complete: return <Home />
    default: return <Home />
  }
}
