import { useEffect, useState } from 'react'
import { useForm, useWatch } from "react-hook-form";
import { Modal, Button } from 'react-bootstrap'
import { Answer, ApiClient, isApiError } from '../../ApiClient';
import RenderProfileField from './RenderProfileField'
import { getLoggedInUser, redirectToHome, saveUser, removeLogin, gaEventTrack, hjEventTrack, StorageLocation, getStorageLocation } from '../../utils';
import './EditProfile.scss';
import { useAuthenticationRedirect, useHotJarStateChange } from '../../CustomHooks';
import { useNavigate } from 'react-router-dom';

export default function EditProfile(): JSX.Element {
  // other states
  useAuthenticationRedirect();
  const navigate = useNavigate();
  const [user] = useState(getLoggedInUser());
  const [details, setDetails] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState("A$");
  useHotJarStateChange();
  const { register, control, handleSubmit, formState: { errors }, setValue } = useForm();
  const headquarters = useWatch({ control, name: "headquarters" });

  // modal controllers
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showBackToDashboardConfirmation, setShowBackToDashboardConfirmation] = useState(false);
  const handleBackToDashboardConfirmationClose = () => setShowBackToDashboardConfirmation(false);
  const handleBackToDashboardConfirmationShow = () => setShowBackToDashboardConfirmation(true);
  const [showBackToDashboardConfirmationAfterSave, setShowBackToDashboardConfirmationAfterSave] = useState(false);
  const handleBackToDashboardConfirmationCloseAfterSave = () => setShowBackToDashboardConfirmationAfterSave(false);
  const handleBackToDashboardConfirmationShowAfterSave = () => setShowBackToDashboardConfirmationAfterSave(true);

  //password show hide controllers
  const [currentPasswordInputFieldType, setCurrentPasswordInputFieldType] = useState('password');
  const [newPasswordInputFieldType, setNewPasswordInputFieldType] = useState('password');
  const [newRetypePasswordInputFieldType, setNewRetypePasswordInputFieldType] = useState('password');

  // password containers
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newRetypePassword, setNewRetypePassword] = useState('');
  
  // error messages
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [newRetypePasswordError, setNewRetypePasswordError] = useState('');

  // flag containers
  const [currentPasswordTypingFlag, setCurrentPasswordTypingFlag] = useState(false);
  const [newPasswordTypingFlag, setNewPasswordTypingFlag] = useState(false);
  const [newRetypePasswordTypingFlag, setNewRetypePasswordTypingFlag] = useState(false);
  const [passwordUpdatingFlag, setPasswordUpdatingFlag] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  // password validation controllers
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isValidPasswordWithAtLeastEightCharacters, setIsValidPasswordWithAtLeastEightCharacters] = useState(false);
  const [isValidPasswordWithAtLeastOneNumber, setIsValidPasswordWithAtLeastOneNumber] = useState(false);
  const [isValidPasswordWithAtLeastOneSymbol, setIsValidPasswordWithAtLeastOneSymbol] = useState(false);

  // regex containers
  const newPasswordRegexAtLeastOneNumber = new RegExp(/.*[0-9].*/)
  const newPasswordRegexAtLeastOneSymbol = new RegExp(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)
  const newPasswordRegexAtLeastEightCharacters = new RegExp(/^.{8,}$/)

  // switch field type between 'text' and 'password'
  const togglePasswordInputFieldType = (data) =>  {
    if(data === 'password'){
      return 'text'
    }else{
      return 'password'
    }
  }

  // get user details and create object with user details
  useEffect(() => {
    document.title = 'My profile';
    (async () => setDetails(await new ApiClient().getRegistrationDetails()))();

    if (!user) {
      return;
    }
    if (!passwordUpdatingFlag){
      user.answers.forEach((answer) => {
        if (answer['sub-questions']) {
          answer['sub-questions'].forEach((sq) => {
            if (sq.type !== "file") {
              setValue(`${answer.name}.${sq.name}`, sq.answer);
            }
          })
        }
        else {
          setValue(answer.name, answer.answer);
        }
      })
    }
  }, [])

  // set HQ location
  useEffect(() => {
    if (!headquarters) {
      return;
    }
    if (headquarters?.includes("Australia")) {
      setCurrencySymbol("A$");
    }
    else if (headquarters?.includes("New Zealand")) {
      setCurrencySymbol("NZ$");
    }
    else {
      setCurrencySymbol("US$");
    }
  }, [headquarters]);

  // submit function
  const onSubmit = async (data) => {
    if(data['employee-count'] === 0) { data['employee-count'] = 1 }
    if (!user) {
      console.error("Could not find user");
      return;
    }
    // submit passwords
    if(passwordUpdatingFlag){
      const client = new ApiClient();
      const result = await client.changePassword(currentPassword, newPassword);
      if (isApiError(result)) {
        setCurrentPasswordError(result.message);
        return
      }
      setCurrentPasswordError('');
    }

    // submit details
    const answers = await mapDetailsToAnswers(details, data);
    const client = new ApiClient()
    const result = await client.updateUserProfile(answers);
    if (isApiError(result)) {
      setCurrentPasswordError(result.message);
      return;
    }
    
    let remember = getStorageLocation() === StorageLocation.Local
    saveUser({ ...result, sectionAnswers: user.sectionAnswers }, remember);
    handleBackToDashboardConfirmationShowAfterSave()
    gaEventTrack("my profile page","save profile details clicked","button")
    hjEventTrack("save profile details clicked")
  };

  // get data of profile details
  const getAnswer = (answers1: Answer[], keys: string[]) => {
    const mainKey = keys.shift();
    const mainQuestion = answers1.find(ans => ans.name === mainKey);
    if (keys.length === 0) {
      return mainQuestion?.answer;
    }
    let answer;
    for (const key of keys) {
      answer = mainQuestion?.["sub-questions"]?.find(sq => sq.name === key);
    }
    return answer?.answer;
  }

  // map a detail to an answer
  const mapDetailToAnswer = async (keyPath: string[], value: any, datum: any): Promise<Answer> => {
    const key = keyPath[keyPath.length - 1];
    const answer: Answer = {
      "name": key,
      "type": value.type,
      "question": value.question,
    }
    if (value["sub-questions"]) {
      const subQuestions = Object.entries(value["sub-questions"]);
      const answers: Answer[] = []
      for (let [subKey, value] of subQuestions) {
        answers.push(await mapDetailToAnswer([...keyPath, subKey], value, datum[key]));
      }
      answer["sub-questions"] = answers;
    }
    else {
      switch (value.type) {
        case 'number': answer.answer = Number.parseInt(datum[key]); break;
        case 'file': answer.answer = datum[key] ?? getAnswer(user!.answers, keyPath); break;
        default: answer.answer = datum[key];
      }
    }
    return answer;
  }

  // map details to answers
  const mapDetailsToAnswers = async (details: any, data: any) => {
    const answerPromises = Object.entries(details).map(async ([key, value]) => await mapDetailToAnswer([key], value, data));
    const answers: Answer[] = []
    for (let answer of answerPromises) {
      answers.push(await answer);
    }
    return answers;
  }

  // redirect to home on navigate
  const onNavigate = async () => {
    redirectToHome(navigate);
  };

  // handle user deletion
  const onDelete = async () => {
    const user = getLoggedInUser();
    if (user === null) {
      console.error("No logged in user");
      return;
    }
    const client = new ApiClient();
    const result = await client.deleteUser(user.id);
    if (isApiError(result)) {
      console.error(result);
      return;
    }
    removeLogin();
    navigate("/login");
  };

  // handle current password entry
  const onCurrentPasswordType = async (data) => {
    setCurrentPasswordTypingFlag(true)
    setNewPasswordTypingFlag(false)
    setNewRetypePasswordTypingFlag(false)

    if(currentPasswordError && data.target.value === 0){setCurrentPasswordError('')}
    
    validatePassword(data.target.value)
    setCurrentPassword(data.target.value)
  };

  // handle new password entry
  const onNewPasswordType = async (data) => {
    setCurrentPasswordTypingFlag(false)
    setNewPasswordTypingFlag(true)
    setNewRetypePasswordTypingFlag(false)

    validatePassword(data.target.value)
    setNewPassword(data.target.value)
  };

  // handle retype password entry
  const onNewPasswordRetype = async (data) => {
    setCurrentPasswordTypingFlag(false)
    // setNewPasswordTypingFlag(false)
    setNewRetypePasswordTypingFlag(true)

    setNewRetypePassword(data.target.value)
    if(newPassword !== data.target.value){
      setNewRetypePasswordError('Passwords do not match')
    } else {
      setNewRetypePasswordError('')
    }
  };


  useEffect(() => {
    // raise flag when all password fields have any data
    if(currentPassword.length>0 && newPassword.length>0 && newRetypePassword.length>0){
      setPasswordUpdatingFlag(true)
    } else {
      setPasswordUpdatingFlag(false)
    }

    // validate password input fields and disable save button dynamically
    if(newRetypePassword !== newPassword){
      setDisableSaveButton(true)
    } else if ((newPassword.length > 0 && newRetypePassword.length > 0) && currentPassword.length === 0){
      setDisableSaveButton(true)
    } else if ((newPassword.length === 0 && newRetypePassword.length === 0) && currentPassword.length > 0){
      setDisableSaveButton(true)
    } else {
      setDisableSaveButton(false)
    }

    // validate password with regex and generate error message
    // adding into a function will cause a 1 step render lag(shows previous value not current value) due to how useState works
    if(currentPasswordTypingFlag){
      if (!isValidPassword){ setCurrentPasswordError(
        !isValidPasswordWithAtLeastOneNumber ? 'At least one number is required' 
            : !isValidPasswordWithAtLeastOneSymbol ? 'At least one symbol is required' 
                : !isValidPasswordWithAtLeastEightCharacters ? 'At least 8 characters are required'
                    : ''
      )} else { setCurrentPasswordError('') }
    } else { setCurrentPasswordError('') }
    if(currentPassword.length===0){setCurrentPasswordError('')}

    if(newPasswordTypingFlag){
      if (!isValidPassword){ setNewPasswordError(
        !isValidPasswordWithAtLeastOneNumber ? 'At least one number is required' 
            : !isValidPasswordWithAtLeastOneSymbol ? 'At least one symbol is required' 
                : !isValidPasswordWithAtLeastEightCharacters ? 'At least 8 characters are required'
                    : ''
      )} else { setNewPasswordError('') }
    } else { setNewPasswordError('') }
    if(newPassword.length===0){setNewPasswordError('')}

    if(newRetypePasswordTypingFlag){
      if(newRetypePassword !== newPassword && newRetypePassword.length>0){ setNewRetypePasswordError('Passwords do not match')
      } else { setNewRetypePasswordError('') }
    }
    if(newRetypePassword.length===0){setNewRetypePasswordError('')}
    
  }, [
      currentPassword, 
      newPassword, 
      newRetypePassword, 
      isValidPasswordWithAtLeastOneNumber, 
      isValidPasswordWithAtLeastEightCharacters, 
      isValidPasswordWithAtLeastOneSymbol, 
      isValidPassword, 
      currentPasswordTypingFlag, 
      newPasswordTypingFlag, 
      newRetypePasswordTypingFlag
    ]
  )

  // validate password with regex
  const validatePassword = async (newPassword) => {
    setIsValidPasswordWithAtLeastOneNumber(newPasswordRegexAtLeastOneNumber.test(newPassword))
    setIsValidPasswordWithAtLeastOneSymbol(newPasswordRegexAtLeastOneSymbol.test(newPassword))
    setIsValidPasswordWithAtLeastEightCharacters(newPasswordRegexAtLeastEightCharacters.test(newPassword))

    setIsValidPassword(isValidPasswordWithAtLeastEightCharacters && isValidPasswordWithAtLeastOneNumber && isValidPasswordWithAtLeastOneSymbol)
  };

  return (
    <div className="company-profile-page">
      <div className="container">
        <div className='row justify-content-md-center'>
          <div className='col-xl-6 col-lg-8'>
            <Button variant="link" className="btn-back" onClick={handleBackToDashboardConfirmationShow}>Back to Dashboard </Button>
            <h1 className="head-2 mb-4">My profile</h1>
            <p className='subtext mb-5'>All My profile fields are mandatory.</p>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
              {Object.entries(details).map(([key, value]: [string, any]) =>
                <RenderProfileField
                  control={control}
                  questionKey={key}
                  questionValue={value}
                  register={register}
                  error={errors[key]}
                  options={{ currencySymbol }} 
                  />
              )}

              <div className='footer-section'>
                <div className='login-details'>
                  <h1 className="head-2 mb-4">Log in details</h1>
                  <p className="subtext mb-5">You may opt to update your password.</p>
                  <div>
                    <div className="form-group email">
                      <label>Email address</label> 
                      <input disabled type="email" className="form-control" id="userEmail" placeholder={user?.email}></input>
                    </div>

                    <div className="form-group wr-pwd-field">
                      <label>Current password</label>
                      <input 
                        onKeyUp={onCurrentPasswordType} 
                        type={currentPasswordInputFieldType} 
                        className="form-control" 
                        id="userCurrentPassword" 
                        placeholder="Enter your current password">
                      </input>
                      <Button 
                      variant="light" 
                      className="button-outline show-password" 
                      id="showCurrentPassword" 
                      onClick={() => 
                        setCurrentPasswordInputFieldType(togglePasswordInputFieldType(currentPasswordInputFieldType))}
                      >{currentPasswordInputFieldType === 'password' ? 'Show' : 'Hide'}</Button>
                    </div>
                    {currentPasswordError ? <div className="error-text">{currentPasswordError}</div> : ""}

                    <div className="form-group wr-pwd-field">
                      <label>New password</label> 
                      <input 
                        onKeyUp={onNewPasswordType} 
                        type={newPasswordInputFieldType} 
                        className="form-control" 
                        id="userNewPassword" 
                        placeholder="min. 8 characters (must include at least 1 number and 1 symbol)">
                      </input>
                      <Button 
                      variant="light" 
                      className="button-outline show-password" 
                      id="showNewPassword" 
                      onClick={() => 
                        setNewPasswordInputFieldType(togglePasswordInputFieldType(newPasswordInputFieldType))}
                      >{newPasswordInputFieldType === 'password' ? 'Show' : 'Hide'}</Button>
                    </div>
                    {newPasswordError ? <div className="error-text">{newPasswordError}</div> : ""}

                    <div className="form-group wr-pwd-field">
                      <label>Retype new password</label>
                      <input 
                        onKeyUp={onNewPasswordRetype} 
                        type={newRetypePasswordInputFieldType} 
                        className="form-control" 
                        id="userNewPasswordRetype" 
                        placeholder="min. 8 characters (must include at least 1 number and 1 symbol)"
                        disabled={newPassword.length <= 0 && newRetypePassword.length===0} 
                        required={newPassword.length > 0}>
                      </input>
                      <Button 
                      variant="light" 
                      className="button-outline show-password" 
                      id="showRetypePassword" 
                      onClick={() => 
                      setNewRetypePasswordInputFieldType(togglePasswordInputFieldType(newRetypePasswordInputFieldType))}
                      >{newRetypePasswordInputFieldType === 'password' ? 'Show' : 'Hide'}</Button>
                    </div>    
                    {newRetypePasswordError ? <div className="error-text">{newRetypePasswordError}</div> : ""}        
                  </div>
                </div>

                {/* delete, cancel and save buttons */}
                <div className="button-section">
                  { <Button variant="light" className="button-outline" onClick={handleShow}>Delete My Profile</Button> }
                  <div>
                    { <Button variant="light"className="pill-button" onClick={onNavigate}>Cancel</Button> }
                    { <Button disabled={disableSaveButton} variant="primary" type="submit">Save</Button> }
                  </div>
                </div>
              </div>
            </form >

            {/* profile delete confirtmation modal */}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirm Delete Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              Deleting your profile will delete all your data, including your answers to the intangible assessment. This can not be reversed. Are you sure you want to go ahead and delete your profile?
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between justify-content-sm-end">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={onDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            {/* back to dashboard confirmation modal */}
            <Modal
              show={showBackToDashboardConfirmation}
              onHide={handleBackToDashboardConfirmationClose}
              backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Back to Dashboard</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Going back will erase all unsaved data. This can not be reversed. Are you sure you want to go back to the dashboard?
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between justify-content-sm-end">
                <Button variant="secondary" onClick={handleBackToDashboardConfirmationClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={onNavigate}>
                  Go To Dashboard
                </Button>
              </Modal.Footer>
            </Modal>

            {/* back to dashboard confirmation modal after save data */}
            <Modal
              show={showBackToDashboardConfirmationAfterSave}
              onHide={handleBackToDashboardConfirmationCloseAfterSave}
              backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Details saved successfully</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              All changes are saved successfully. If you have changed your password, it will take effect upon your next log in.
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between justify-content-sm-end">
                <Button variant="secondary" onClick={handleBackToDashboardConfirmationCloseAfterSave}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => navigate("/")}>
                  Go To Dashboard
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}
