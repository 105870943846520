export const HYFEN8_WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const HYFEN8_PORTAL_BACKEND_URL = process.env.REACT_APP_PORTAL_BACKEND_URL;
export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";
export const LOGGED_IN_USER_KEY = "user";
export const PROFILE_CREATE_SAVED_USER_KEY = "profile_save_user";
export const NEW_ASSESSMENT_ID = "new_assessment_id";
export const LATEST_ASSESSMENT_ID = "latest_assessment_id";
export const ACTIVE_ASSESSMENTS = process.env.REACT_APP_ACTIVE_ASSESSMENTS?.split(",")

const yellow = "#EBAB21";
const orange = "#F36C25";
const rubyred = "#E5344E";
const earth = "#A04923";
const purple = "#AF3192";
const darkpurple = "#553193";
const teal = "#00646B";
const green = "#05A189";

export const SECTIONS = [
  { number: 1, name: "Operating Model", colour: yellow },
  { number: 2, name: "Ecosystem", colour: green },
  { number: 3, name: "Brand", colour: rubyred },
  { number: 4, name: "Data", colour: purple },
  { number: 5, name: "Knowhow", colour: earth },
  { number: 6, name: "Rights", colour: teal },
  { number: 7, name: "People", colour: orange },
  { number: 8, name: "Business Model", colour: darkpurple },
];

export const HOTJAR_SETTINGS = {
  id: Number(process.env.REACT_APP_HOTJAR_SETTINGS_ID),
  version: Number(process.env.REACT_APP_HOTJAR_SETTINGS_VERSION),
};
export const GOOGLE_ANALYTICS_TRACK_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_ID as string;
