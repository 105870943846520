import { useEffect, useState } from 'react'
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Button, Form } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ApiClient, isApiError } from '../ApiClient';
import { useHotJarStateChange, useQuery } from '../CustomHooks';
import { useAuthenticationRedirect } from '../CustomHooks';
import { redirectToLogin } from '../utils';

export default function ResetPassword() {
  useAuthenticationRedirect(false);
  const { token } = useQuery();
  const navigate = useNavigate();
  const [isActionSuccessful, setIsActionSuccessful] = useState<boolean>(false);
  const [serverErrorMessage, setServerErrorMessage] = useState<string>();
  useHotJarStateChange();

   //password show hide controllers
   const [newPasswordInputFieldType, setNewPasswordInputFieldType] = useState('password');
   const [newRetypePasswordInputFieldType, setNewRetypePasswordInputFieldType] = useState('password');
 
   // password containers
   const [newPassword, setNewPassword] = useState('');
   const [newRetypePassword, setNewRetypePassword] = useState('');
   
   // error messages
   const [newPasswordError, setNewPasswordError] = useState('');
   const [newRetypePasswordError, setNewRetypePasswordError] = useState('');
 
   // flag containers
   const [newPasswordTypingFlag, setNewPasswordTypingFlag] = useState(false);
   const [newRetypePasswordTypingFlag, setNewRetypePasswordTypingFlag] = useState(false);
   const [disableSaveButton, setDisableSaveButton] = useState(false);
 
   // password validation controllers
   const [isValidPassword, setIsValidPassword] = useState(false);
   const [isValidPasswordWithAtLeastEightCharacters, setIsValidPasswordWithAtLeastEightCharacters] = useState(false);
   const [isValidPasswordWithAtLeastOneNumber, setIsValidPasswordWithAtLeastOneNumber] = useState(false);
   const [isValidPasswordWithAtLeastOneSymbol, setIsValidPasswordWithAtLeastOneSymbol] = useState(false);
 
   // regex containers
   const newPasswordRegexAtLeastOneNumber = new RegExp(/.*[0-9].*/)
   const newPasswordRegexAtLeastOneSymbol = new RegExp(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)
   const newPasswordRegexAtLeastEightCharacters = new RegExp(/^.{8,}$/)

     // switch field type between 'text' and 'password'
  const togglePasswordInputFieldType = (data) =>  {
    if(data === 'password'){
      return 'text'
    }else{
      return 'password'
    }
  }

  useEffect(() => {
    if (!token) {
      redirectToLogin(navigate);
    }
  }, [token, navigate]);

  const resetPasswordSchema = Joi.object().keys({
    password: Joi.string().min(8).required().messages({
      'string.empty': "Required",
      'string.min': "Password must be at least {#limit} characters",
    }),
    "confirm-password": Joi.string().required().valid(Joi.ref('password')).messages({
      'any.only': "Passwords do not match",
    }),
  });

  const { register, handleSubmit } = useForm({
    resolver: joiResolver(resetPasswordSchema)
  });

  const onSubmit = async (data: { [x: string]: any; }) => {
    const client = new ApiClient();
    const result = await client.resetPassword(token, data.password);
    if (isApiError(result)) {
      setServerErrorMessage(result.message);
      return;
    }
    setServerErrorMessage(undefined);
    setIsActionSuccessful(true);
    setTimeout(() => {
      redirectToLogin(navigate);
    }, 3000);
  };

  // type new password
  const onNewPasswordType = async (data) => {
    setNewPasswordTypingFlag(true)
    setNewRetypePasswordTypingFlag(false)

    validatePassword(data.target.value)
    setNewPassword(data.target.value)
  };

  // retype new password
  const onNewPasswordRetype = async (data) => {
    setNewRetypePasswordTypingFlag(true)

    setNewRetypePassword(data.target.value)
    if(newPassword !== data.target.value){
      setNewRetypePasswordError('Passwords do not match')
    } else {
      setNewRetypePasswordError('')
    }
  };

  // validate password with regex and generate error message
  // adding into a function will cause a 1 step render lag(shows previous value not current value) due to how useState works
  useEffect(() => {
    if(newPasswordTypingFlag){
      if (!isValidPassword){ setNewPasswordError(
        !isValidPasswordWithAtLeastOneNumber ? 'At least one number is required' 
            : !isValidPasswordWithAtLeastOneSymbol ? 'At least one symbol is required' 
                : !isValidPasswordWithAtLeastEightCharacters ? 'At least 8 characters are required'
                    : ''
      )} else { setNewPasswordError('') }
    } else { setNewPasswordError('') }
    if(newPassword.length===0){setNewPasswordError('')}

    if(newRetypePasswordTypingFlag){
      if(newRetypePassword !== newPassword && newRetypePassword.length>0){ setNewRetypePasswordError('Passwords do not match')
      } else { setNewRetypePasswordError('') }
    }
    if(newRetypePassword.length===0){setNewRetypePasswordError('')}
  }, [newPassword, newRetypePassword, isValidPasswordWithAtLeastOneNumber, isValidPasswordWithAtLeastEightCharacters, isValidPasswordWithAtLeastOneSymbol, isValidPassword, newPasswordTypingFlag, newRetypePasswordTypingFlag])

  // validate password with regex
  const validatePassword = async (newPassword) => {
    setIsValidPasswordWithAtLeastOneNumber(newPasswordRegexAtLeastOneNumber.test(newPassword))
    setIsValidPasswordWithAtLeastOneSymbol(newPasswordRegexAtLeastOneSymbol.test(newPassword))
    setIsValidPasswordWithAtLeastEightCharacters(newPasswordRegexAtLeastEightCharacters.test(newPassword))

    setIsValidPassword(isValidPasswordWithAtLeastEightCharacters && isValidPasswordWithAtLeastOneNumber && isValidPasswordWithAtLeastOneSymbol)
  };

  // validate password input fields and disable save button dynamically
  useEffect(() => {
    if(newRetypePassword !== newPassword){
      setDisableSaveButton(true)
    } else if ((newPassword.length === 0 && newRetypePassword.length === 0)){
      setDisableSaveButton(true)
    } else {
      setDisableSaveButton(false)
    }
  }, [newPassword, newRetypePassword])

  return (
    <div>
      <div className="reset-pass-page">
        <div className="container">
          <h1 className="text-1 mx-auto mb-2">Forgot password</h1>
          <p className="text-3 mb-5">
            Email address verified. You can now enter and confirm your new
            password.
          </p>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex flex-column mx-auto"
          >
            <div className="mb-4">
            <Form.Label className="text-2">New password.</Form.Label>
            <Form.Group
              className="form-group wr-pwd-field"
              controlId="password"
            >
              <Form.Control
                onKeyUp={onNewPasswordType}
                type={newPasswordInputFieldType}
                className="form-control"
                placeholder="min. 8 characters (must include at least 1 number and 1 symbol)"
                {...register("password")}
              />
              <Button
                variant="light"
                className="button-outline show-password"
                id="showPassword"
                onClick={() =>
                  setNewPasswordInputFieldType(
                    togglePasswordInputFieldType(newPasswordInputFieldType)
                  )
                }
              >
                {newPasswordInputFieldType === "password" ? "Show" : "Hide"}
              </Button>
            </Form.Group>
              {newPasswordError ? (
                <div className="error-text">
                  {" "}
                  <div className="error-text">{newPasswordError}</div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mb-5">
            <Form.Label className="text-2">Confirm Password</Form.Label>
            <Form.Group
              className="form-group wr-pwd-field"
              controlId="confirm-password"
            >
              <Form.Control
                onKeyUp={onNewPasswordRetype}
                disabled={
                  newPassword.length <= 0 && newRetypePassword.length === 0
                }
                required={newPassword.length > 0}
                type={newRetypePasswordInputFieldType}
                {...register("confirm-password")}
                placeholder="min. 8 characters (must include at least 1 number and 1 symbol)"
              />
              <Button
                variant="light"
                className="button-outline show-password"
                id="showPassword"
                onClick={() =>
                  setNewRetypePasswordInputFieldType(
                    togglePasswordInputFieldType(
                      newRetypePasswordInputFieldType
                    )
                  )
                }
              >
                {newRetypePasswordInputFieldType === "password"
                  ? "Show"
                  : "Hide"}
              </Button>
            </Form.Group>
              {newRetypePasswordError ? (
                <div className="error-text">
                  <div className="error-text">{newRetypePasswordError}</div>{" "}
                </div>
              ) : (
                ""
              )}
            </div>

            {serverErrorMessage ? <div className="error-text">{serverErrorMessage}</div> : ""}
            {isActionSuccessful && <div className="mb-3">
              Password changed successfully. Please wait while we redirect you to the login page.
            </div>}
            <div className="d-flex justify-content-end">
              {!isActionSuccessful &&
                <Button variant="primary" type="submit"  disabled={disableSaveButton}>CONTINUE</Button>
              }
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

