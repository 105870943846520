import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { toBase64 } from '../../utils';

const MAX_FILE_SIZE = 25; //MB

type Props =
    {
        name: string,
        onChange: any,
        value: any,
        placeholder: string,
        accept?: string,
        className?: string,
    };
const FileUploader = React.forwardRef<HTMLInputElement, Props>(({
    name,
    onChange,
    placeholder,
    accept,
    className,
    value,
}, ref) => {
    const [fileName, setFileName] = useState<string>();
    const [valueInternal, setValueInternal] = React.useState<any>(null);
    const [showAlert, setShowAlert] = useState(false)
    const inputFileRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (value) {
            setFileName("File uploaded to server")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        inputFileRef?.current?.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        const file = !!input.files?.length ? input.files[0] : null;
        setValueInternal(input.value);
        if (!file) {
            setFileName(undefined);
            onChange(undefined);
            return;
        }
        if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            setShowAlert(true);
            return;
        }
        setFileName(file.name);
        const fileB64String = await toBase64(file)
        onChange(fileB64String);
    };
    return (
        <div>
            <Form.Text muted>{`Max file size ${MAX_FILE_SIZE}mb`}</Form.Text>
            <div className={`custom-file_input d-flex ${className}`}>
                <Form.Control className="mr-3" placeholder={fileName || placeholder || "Choose File"} disabled />
                <Button onClick={handleClick} variant="primary">
                    Upload
                </Button>
                <input
                    value={valueInternal}
                    name={name}
                    ref={inputFileRef}
                    type="file"
                    onChange={handleChange}
                    accept={accept}
                    style={{ display: 'none' }}
                />
            </div>
            {showAlert &&
                <Modal
                    show={showAlert}
                    onHide={() => setShowAlert(false)}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>File too large</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {`File exceeds the max upload size of ${MAX_FILE_SIZE}mb`}
                    </Modal.Body>
                </Modal>
            }
        </div>
    );
});
export default FileUploader;