import React, { useEffect, useRef } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

const currencyFormatter = new Intl.NumberFormat('en-AU', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

type Props =
    {
        name: string,
        currencySymbol: string,
        onChange: any,
        value: any,
        placeholder: string,
        className?: string,
        allowNegative?: boolean,
    };
const CurrencyInput = React.forwardRef<HTMLInputElement, Props>(({ name, currencySymbol, onChange, value, placeholder, className, allowNegative = true }, ref) => {
    const currencyInputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (value == null) {
            return;
        }
        formatInput(value.toString())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = currencyInputRef.current as HTMLInputElement;
        onChange(formatInput(input.value));
    };

    const formatInput = (inputValue: string) => {
        if (inputValue === "") {
            return "";
        }
        if (allowNegative && inputValue === "-") {
            return "";
        }
        let value = Number.parseInt(inputValue.replaceAll(",", ""));
        value = allowNegative ? value : Math.abs(value);
        currencyInputRef.current!.value = isNaN(value) ? "" : currencyFormatter.format(value);
        return currencyInputRef.current!.value;
    }

    return (
        <InputGroup className="">
            <InputGroup.Text>{currencySymbol}</InputGroup.Text>
            <FormControl
                name={name}
                ref={(e: HTMLInputElement | null) => {
                    (ref as ((instance: HTMLInputElement | null) => void))(e)
                    currencyInputRef.current = e
                }}
                onChange={handleChange}
                placeholder={placeholder}
            />
        </InputGroup>
    );
});
export default CurrencyInput;