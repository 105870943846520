import { MenuItem, Select, Slider } from '@mui/material';
import { ReactElement } from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import { Control, Controller, FieldValues, UseFormRegister } from 'react-hook-form'
import classNames from "classnames";

import FileUploader from '../CustomFields/FileUploader'
import LogarithmicSlider from '../CustomFields/LogarithmicSlider';

import './RenderQuestionnaireSectionField.scss'
import { QuestionModel } from '../../ApiClient';
import { CurrencyInput } from '../CustomFields';
import { HYFEN8_PORTAL_BACKEND_URL } from '../../Constants';

interface FieldMapFn {
  (control: Control, questionKey: string, questionValue: any, error: any): ReactElement
}
interface FieldMapping {
  radio: FieldMapFn,
  select: FieldMapFn,
  slider: FieldMapFn,
  logSlider: FieldMapFn,
  number: FieldMapFn,
  currency: FieldMapFn,
  percentage: FieldMapFn,
  file: FieldMapFn,
  textarea: FieldMapFn,
};
type RenderQuestionnaireSectionFieldProps = {
  control: Control,
  questionKey: string,
  questionValue: QuestionModel,
  register: UseFormRegister<FieldValues>,
  error: any,
  disabled: boolean,
  answer:any
}

export default function RenderQuestionnaireSectionField({ control, questionKey, questionValue, register, error, disabled, answer }: RenderQuestionnaireSectionFieldProps): JSX.Element {
  
  const renderRadio = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <div>
        {questionValue.choices.map((choice: string, index: number) => (
          <Form.Check
            key={index}
            type="radio"
            {...register(questionKey, { required: questionValue.required !== false })}
            label={choice}
            value={choice}
            inline={questionValue.inline}
          />
        ))}
        {error ? <div className="error-text">Required</div> : <></>}
      </div>
    );
  };

  const renderSelect = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <>
        <Controller
          render={
            ({ field }) => 
            <Select 
            {...field} 
            value={field.value || []}  
            multiple={questionValue.multi} 
            disabled={disabled}
            renderValue={(selected) => {
              // condition true only for Knowhow dropdown
              if(questionKey === '2.1' && questionValue.question === "Select which of the following business processes provide you with commercial advantages over competitors."){
                questionValue.dontKnowSelected = false
                questionValue.noneRelevantSelected = false
                questionValue.positiveSelected = false
                
                if(selected.includes("None relevant")){
                  questionValue.dontKnowSelected = false
                  questionValue.noneRelevantSelected = true
                  questionValue.positiveSelected = false
                
                } else if(selected.includes("None of the above / don't know")){
                  questionValue.dontKnowSelected = true
                  questionValue.noneRelevantSelected = false
                  questionValue.positiveSelected = false
                
                } else if(selected.length>0 && (!selected.includes("None relevant") || !selected.includes("None of the above / don't know"))){
                  questionValue.dontKnowSelected = false
                  questionValue.noneRelevantSelected = false
                  questionValue.positiveSelected = true
                
                } else {
                  questionValue.dontKnowSelected = false
                  questionValue.noneRelevantSelected = false
                  questionValue.positiveSelected = false
                }
              }
              if (!selected || selected.length === 0) {
                return <em>{questionValue.placeholder}</em>;
              }
              return questionValue.multi ? selected.join(', ') : selected;
            }} 
            displayEmpty 
            
            sx={{width: "100%"}}>
              <MenuItem value="none" disabled>{questionValue.placeholder}</MenuItem>
              {
                questionValue.choices.map((choice: string) => {
                  // condition true only for Knowhow dropdown
                  if(questionKey === '2.1' && questionValue.question === "Select which of the following business processes provide you with commercial advantages over competitors."){
                    
                    if(questionValue.dontKnowSelected){
                      if(choice === "None relevant" || choice !== "None of the above / don't know" ){
                        return <MenuItem disabled={true} key={choice} value={choice}>{choice}</MenuItem>
                      } else {
                        return <MenuItem disabled={false} key={choice} value={choice}>{choice}</MenuItem>
                      }
                    
                    } else if(questionValue.noneRelevantSelected) {
                      if(choice === "None of the above / don't know" || choice !== "None relevant" ){
                        return <MenuItem disabled={true} key={choice} value={choice}>{choice}</MenuItem>
                      } else {
                        return <MenuItem disabled={false} key={choice} value={choice}>{choice}</MenuItem>
                      }
                    
                    } else if(questionValue.positiveSelected) {
                      if(choice === "None relevant" || choice === "None of the above / don't know"){
                        return <MenuItem disabled={true} key={choice} value={choice}>{choice}</MenuItem>
                      } else {
                        return <MenuItem disabled={false} key={choice} value={choice}>{choice}</MenuItem>
                      }
                    
                    } else {
                      return <MenuItem disabled={false} key={choice} value={choice}>{choice}</MenuItem>
                    }
                  } else {
                    if (typeof choice === "object") {
                      const [optionGroup, options]: [string, string[]] = Object.entries(choice)[0] as any;
                      const optionGroupMenuItem = <MenuItem className="option-group" key={optionGroup} value={optionGroup} disabled={true}>{optionGroup}</MenuItem>;
                      const optionsMenuItems = options.map((option) => <MenuItem key={option} value={`${option}, ${optionGroup}`}>{option}</MenuItem>)
                      return [optionGroupMenuItem, ...optionsMenuItems];
                    }
                    return <MenuItem key={choice} value={choice}>{choice}</MenuItem>    
                  }
                })
              }
            </Select >
          }
          control={control}
          name={questionKey}
          defaultValue={answer || []}
          rules={{ required: questionValue.required !== false }}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </>
    );
  };

  const renderSlider = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <>
        <Controller
          render={
            ({ field }) => <Slider
              {...field}
              step={1}
              valueLabelDisplay="auto"
              marks={questionValue.marks}
              min={Math.min(...questionValue.marks.map((m: any) => m.value))}
              max={Math.max(...questionValue.marks.map((m: any) => m.value))}
            />
          }
          control={control}
          name={questionKey}
          defaultValue={Math.min(...questionValue.marks.map((m: any) => m.value))}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </>
    );
  };

  const renderLogSlider = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <>
        <Controller
          render={
            ({ field }) => <LogarithmicSlider
              {...field}
              valueLabelDisplay="auto"
              marks={questionValue.marks}
              min={Math.min(...questionValue.marks.map((m: any) => m.value))}
              max={Math.max(...questionValue.marks.map((m: any) => m.value))}
            />
          }
          control={control}
          name={questionKey}
          defaultValue={Math.min(...questionValue.marks.map((m: any) => m.value))}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </>
    );
  };

  const renderInput = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    const validationErrorMessages: { [x: string]: string; } = {
      min: `Value must be at least ${questionValue.min}`,
      max: `Value must be at most ${questionValue.max}`,
      pattern: `Value must be an integer`,
      required: `Required`,
    };
    return (
      <div className="justify-content-sm-end align-items-center">
        <Form.Control
          type={questionValue.type}
          {...register(questionKey, {
            required: questionValue.required !== false,
            min: questionValue.min,
            max: questionValue.max,
            pattern: questionValue.allowDecimal === false ? /^\d+$/i : undefined,
          })}
          placeholder={questionValue.placeholder}
        />
        {error ? <div className="error-text">{validationErrorMessages[error.type]}</div> : <></>}
      </div>
    );
  };

  const renderCurrencyInput = (control: Control, questionKey: string, questionValue: any, error: any, options?: any): ReactElement => {
    const currencySymbol = options?.currencySymbol ?? "A$";
    return (
      <div className="d-sm-flex flex-column justify-content-sm-end align-items-start">
        <Controller
          control={control}
          name={questionKey}
          render={
            ({ field }) => <CurrencyInput
              {...field}
              currencySymbol={currencySymbol}
              placeholder={questionValue.placeholder}
              allowNegative={questionValue.allowNegative}
            />
          }
          rules={{ required: questionValue.required }}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </div>
    );
  };

  const renderPercentageInput = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <div className="justify-content-sm-end align-items-center">
        {questionValue.required === false ? <Form.Text muted>Leave empty if you don't know</Form.Text> : <></>}
        <InputGroup>
          <Form.Control
            type="number"
            {...register(questionKey, { required: questionValue.required !== false, min: questionValue.min ?? undefined })}
            placeholder={questionValue.placeholder}
            step="0.0001"
          />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
        {error && <div className="error-text">{error.type === "min" ? "Value cannot be negative" : "Required"}</div>}
      </div>
    );
  };

  const renderFileInput2 = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <div className="d-sm-flex justify-content-sm-end">
        <Controller
          control={control}
          name={questionKey}
          render={({ field }) => (
            <FileUploader
              {...field}
              placeholder={questionValue.placeholder}
            />
          )}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </div>
    );
  };

  const renderTextArea = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <>
        <Form.Control
          as="textarea"
          type={questionValue.type}
          {...register(questionKey, { required: questionValue.required !== false })}
          placeholder={questionValue.placeholder}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </>
    );
  };

  const fieldMapping: FieldMapping = {
    radio: renderRadio,
    select: renderSelect,
    slider: renderSlider,
    logSlider: renderLogSlider,
    number: renderInput,
    currency: renderCurrencyInput,
    percentage: renderPercentageInput,
    file: renderFileInput2,
    textarea: renderTextArea,
  };

  const renderField = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <div id={`section-${questionKey}`} key={questionKey} className={classNames("question-container", {
        inline: questionValue.inline,
      })}>
        <div className="question-title mb-3 text-2">
          {questionValue.question}
        </div>
        {questionValue["image-url"] &&
          <div className="question-image-container mb-3">
            <img alt="question" className="question-image" src={`${HYFEN8_PORTAL_BACKEND_URL}${questionValue["image-url"]}`} />
          </div>
        }
        <div className={`question-field-container ${questionValue.type}`}>
          {
            fieldMapping[questionValue.type as keyof FieldMapping](control, questionKey, questionValue, error)
          }
        </div>
      </div>
    )
  }

  return renderField(control, questionKey, questionValue, error);
}
