import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ApiClient, isApiError } from '../ApiClient';
import { useHotJarStateChange, useQuery } from '../CustomHooks';
import { useAuthenticationRedirect } from '../CustomHooks';
import { redirectToRegister, saveLogin } from '../utils';

export default function VerifyEmail() {
  useAuthenticationRedirect(false);
  const [isVerfied, setIsVerfied] = useState(false);
  const { token } = useQuery();
  const navigate = useNavigate();
  useEffect(() => {
    const createAnswersObject = (userResult) => {
      let userNameObj={
        "name": "user-name", 
        "type": "text", 
        "question": "Your name", 
        "answer": (userResult.user.name)
      }
      let userRoleObj={
        "name": "user-role",
        "type": "text",
        "question": "Your role",
        "answer": (userResult.user.role)
      }
      let companyNameObj={
        "name": "company-name",
        "type": "text",
        "question": "Organisation name",
        "answer": (userResult.user.organizationName)
      }
  
      userResult.user.answers.push(userNameObj)
      userResult.user.answers.push(userRoleObj)
      userResult.user.answers.push(companyNameObj)
  
      return userResult
    }
    
    if (!token) {
      redirectToRegister(navigate);
    }
    (async () => {
      const client = new ApiClient();
      const result = await client.verifyEmail(token);
      if (result && isApiError(result)) {
        console.error("Something went wrong while verifying your email", result);
        return;
      }
      setIsVerfied(true);
      if(!(result.user.answers?.length > 0)){
        saveLogin(createAnswersObject(result));
      }
      saveLogin(result);
      setTimeout(() => navigate("/"), 3000);
    })()
  }, [navigate, token])
  useHotJarStateChange();
  return (
    <div className="verify-email-page">
      <div className="container">
        <h1 className="head-1">Account verification</h1>
        {
          isVerfied ?
            <p className="pt-4">Your account has been successfully verified.
            <br/>
            Please wait while we redirect you to the Hyfen8 dashboard.
            </p> :
            <p className="pt-4">Please wait while we verify your email.</p>
        }
      </div>
    </div>
  )
}
