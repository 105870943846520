import React from 'react'
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import { SubSectionModel } from '../../ApiClient'
import SectionQuestion from './SectionQuestion';

type SubSectionProps = {
    subSectionNumber: number,
    subSection: SubSectionModel,
    control: Control<FieldValues, object>,
    register: UseFormRegister<FieldValues>,
    error?: any,
    disabled: boolean,
    userSectionAnswers: any
}

export default function SubSection({ subSectionNumber, subSection, control, register, error, disabled, userSectionAnswers}: SubSectionProps) {
    return (
        <div className="sub-section row">
            <div className="col-md-4 ">
                <div className='sub-section-name'>{subSection.name}</div>
            </div>
            <div className="col-md-8 column-2"> {
                subSection.questions.map((question) =>
                    <SectionQuestion
                        key={question.id} 
                        question={question} 
                        subSectionNumber={subSectionNumber} 
                        control={control} 
                        register={register} 
                        error={error}
                        disabled = {disabled}
                        answer={userSectionAnswers?.find(answer => answer.id === question.id)?.answer}
                    ></SectionQuestion>
                )}
            </div>
        </div>
    )
}
