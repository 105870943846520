import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
  ApiClient,
  isApiError,
  QuestionAnswerModel,
  SectionAnswerModel,
  SectionAnswerStatus,
  SectionModel,
  SubSectionAnswerModel,
} from "../ApiClient";
import "./Section.scss";
import { useForm, useWatch } from "react-hook-form";
import { SubSection } from "./QuestionnaireComponents";
import { useAuthenticationRedirect, useHotJarStateChange } from "../CustomHooks";
import {
  gaEventTrack,
  hjEventTrack,
  getLoggedInUser,
  getStorageLocation,
  redirectToHome,
  saveUser,
  StorageLocation,
} from "../utils";
import { SECTIONS } from "../Constants"

type SectionWithStatusType = {
  number: number;
  name: string;
  colour: any;
  status?: SectionAnswerStatus;
};

const sectionNameToSectionNumberMapper = {
  knowhow: "5",
  people: "7"
}
export default function Section() {
  // others
  useAuthenticationRedirect();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { name } = useParams();
  useHotJarStateChange();

  const {
    register,
    unregister,
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const watchFields = useWatch({ control });
  const [user] = useState(getLoggedInUser());
  const [id] = useState(sectionNameToSectionNumberMapper[name as string]);

  // section states
  const [sectionNumber, setSectionNumber] = useState<number>(NaN);
  const [section, setSection] = useState<SectionModel | null>(null);
  const [sectionsWithStatus, setSectionsWithStatus] = useState<SectionWithStatusType[]>([]);
  const [sectionStatus, setSectionStatus] = useState<string>();

  useEffect(() => {
    let sectionName = name as string
    sectionName = sectionName.charAt(0).toUpperCase() + sectionName.slice(1)
    document.title = sectionName;
  }, [])

  useEffect(() => {
    if (!section) {
      return;
    }
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    formSaveHandler(true);
    if (hasAttemptedSubmit) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFields]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (!id || Number.isNaN(Number.parseInt(id))) {
      redirectToHome(navigate);
      return;
    }
    const sectionNumber = Number.parseInt(id);
    setSectionNumber(sectionNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  useEffect(() => {
    if (Number.isNaN(sectionNumber)) {
      return;
    }
    (async () => {
      const client = new ApiClient();
      let section = await client.getNewSectionData(sectionNumber)

      if (!section || isApiError(section)) {
        redirectToHome(navigate);
        return;
      }
      setSection(section);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionNumber]);

  useEffect(() => {
    if (!user) {
      return;
    }
    let sectionsWithStatus: SectionWithStatusType[] = [];
    SECTIONS.forEach((section) => {
      if(user.sectionAnswers && user.sectionAnswers.length > 0){
        const sectionAnswers = user.sectionAnswers.find(
          (ans) => ans.number === section.number
        );
        sectionsWithStatus.push({
          ...section,
          status: sectionAnswers ? sectionAnswers.status : undefined,
        });
    }});
    setSectionsWithStatus(sectionsWithStatus);
  }, [user]);

  useEffect(() => {
    if(sectionsWithStatus[sectionNumber-1]?.status){
      setSectionStatus(sectionsWithStatus[sectionNumber-1].status || '')
    }
  }, [sectionsWithStatus, sectionNumber])

  useLayoutEffect(() => {
    if (!section) {
      return;
    }
    const user = getLoggedInUser();
    if (!user) {
      return;
    }
    let currentSectionAnswers
    if(user.sectionAnswers && user.sectionAnswers.length > 0) {
      currentSectionAnswers = user.sectionAnswers.find((sa) => sa.number === sectionNumber);
    }
    if (!currentSectionAnswers) {
      return;
    }
    currentSectionAnswers.subSections.forEach((ss: any, ssIndex: number) => {
      ss.answers.forEach((ans: any) => {
        setValue(`${ssIndex}.${ans.id}`, ans.answer);
      });
    });
    return () => {
      const user = getLoggedInUser();
      if (!user) {
        return;
      }
      const currentSectionAnswers = user.sectionAnswers.find(
        (sa) => sa.number === sectionNumber
      );
      if (!currentSectionAnswers) {
        return;
      }
      currentSectionAnswers.subSections.forEach((ss: any, ssIndex: number) => {
        ss.answers.forEach((ans: any) => {
          unregister(`${ssIndex}.${ans.id}`);
        });
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, user]);

  const onSubmit = async () => {
    if (!(await trigger())) {
      setHasAttemptedSubmit(true);
      return;
    }
    await submitSection();
  };

  const submitSection = async () => {
    const data = getValues();
    const subSectionsAnswers: SubSectionAnswerModel[] = [];
    section?.subSections.forEach((ss, index) => {
      const answers: QuestionAnswerModel[] = [];
      ss.questions.forEach((q) => {
        answers.push({
          id: q.id,
          question: q.question,
          answer: data[index][q.id],
        });
        q.conditionalQuestions?.forEach((cq) => {
          if (data[index][cq.id]) {
            answers.push({
              id: cq.id,
              question: cq.question,
              answer: data[index][cq.id],
            });
          }
        });
      });
      subSectionsAnswers.push({
        name: ss.name,
        answers: answers,
      });
    });
    await processAnswers(SectionAnswerStatus.Complete, subSectionsAnswers);
    redirectToHome(navigate)
    hjEventTrack(`${section?.name}-section-complete-button-click`);
    gaEventTrack("section page",`${section?.name}-section-complete button clicked`,"button");
  };

  async function saveHandler() {
    formSaveHandler();
  };

  async function formSaveHandler(change = false) {
    const data = getValues();
    const subSectionsAnswers: SubSectionAnswerModel[] = [];
    section?.subSections.forEach((ss, index) => {
      const answers: QuestionAnswerModel[] = [];
      ss.questions.forEach((q) => {
        if (data[index]?.[q.id] != null) {
          answers.push({
            id: q.id,
            question: q.question,
            answer: data[index][q.id],
          });
        }
        q.conditionalQuestions?.forEach((cq) => {
          if (data[index]?.[cq.id] != null) {
            answers.push({
              id: cq.id,
              question: cq.question,
              answer: data[index][cq.id],
            });
          }
        });
      });
      subSectionsAnswers.push({
        name: ss.name,
        answers: answers,
      });
    });
    await processAnswers(
      SectionAnswerStatus.InProgress,
      subSectionsAnswers,
      change
    );
  };

  async function processAnswers( status: SectionAnswerStatus, subSectionsAnswers: SubSectionAnswerModel[], change = false ) {
    if (!section) {
      return;
    }
    const sectionAnswers: SectionAnswerModel = {
      name: section!.name,
      number: section!.number,
      status,
      subSections: subSectionsAnswers,
    };
    const client = new ApiClient();
    try {
      await client.saveAssessment(sectionNumber, sectionAnswers);
      updateUserSectionAnswers(sectionAnswers);
      if (status === SectionAnswerStatus.InProgress && !change) {
        navigate("/");
      }
    } catch {
      console.error("Processing answers failed");
    }
  };

  function updateUserSectionAnswers(sectionAnswers: SectionAnswerModel) {
    const user = getLoggedInUser();
    if (!user) {
      return;
    }
    const sectionIndex = user.sectionAnswers.findIndex(
      (ans) => ans.number === sectionAnswers.number
    );
    if (sectionIndex === -1) {
      user.sectionAnswers.push(sectionAnswers);
    } else {
      user.sectionAnswers[sectionIndex] = sectionAnswers;
    }
    
    let remember = getStorageLocation() === StorageLocation.Local
    saveUser(user, remember)
  };

  const onNavigate = async () => {
    redirectToHome(navigate);
  };

  return !section ? (
    <div className="container container-fluid page-loader">
      <div className="ldl-spinner">
        <div className="ldl-inner">
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

  ) : (
    <div className="question-page">
      <main className="container section-container">
        <Button variant="link" className="btn-back" onClick={(sectionStatus === 'Complete') ? onNavigate : saveHandler}>Back to Dashboard </Button>
        <form>
          <fieldset disabled={sectionStatus === 'Complete'}>
            <h1 className="d-flex">
              <div
                className="section-dot"
                style={{ backgroundColor: section.themeColour }}
              ></div>
              {section.name}
            </h1>
            <section className="section-description pt-3">
              {section.description}
              <div className="sub-text mt-5">
                Hyfen8 will automatically save your progress. Remember to hit the ‘Complete’ button below to submit your answers.
              </div>
            </section>
            <section className="section-progress pt-3">
              <div
                className="progress-bar-container"
                style={
                  { "--theme-color": section.themeColour } as React.CSSProperties
                }
              >
                <div className="progress-bar progress-outer-bar"></div>
                <div
                  className="progress-bar progress-inner-bar"
                  // style={{ width: `${sectionProgress}%` }}
                  style={{ width: `100%` }}
                ></div>
              </div>
            </section>
            <section className="pt-5">
              {section.subSections.map((subSection, index) => (
                <SubSection
                  key={`${section.number}.${index}`}
                  subSectionNumber={index}
                  subSection={subSection}
                  register={register}
                  control={control}
                  error={errors[index]}
                  // passing disabled to use for the dropdown
                  // as the dropdown does not get disabled even if the form is disabled
                  disabled={sectionStatus === 'Complete'}
                  userSectionAnswers={user?.sectionAnswers.find((ans) => ans.number === section.number)?.subSections[index].answers}
                />
              ))}
            </section>
            <div className="button-section">
              {Object.keys(errors).length !== 0 && <div className="error-text mb-4">There are one or more errors. Please check your answers.</div>}
              <div>
                <Button hidden={sectionStatus === 'Complete'} onClick={(sectionStatus === 'Complete') ? onNavigate : saveHandler} variant="secondary"> Back to dashboard </Button>

                {(sectionStatus === 'Complete')
                  ? <Button disabled variant="primary"> Completed </Button> 
                  : <Button onClick={onSubmit} variant="primary"> Complete </Button> 
                }
              </div>
            </div>
          </fieldset>
        </form>
        <Button hidden={sectionStatus !== 'Complete'} onClick={onNavigate } variant="secondary" className="btn-back-to-db"> Back to dashboard </Button>
      </main>
    </div>
  );
}
