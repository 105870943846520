import { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import useHotjar from "react-use-hotjar";
import {
  Section,
  Login,
  Nav,
  ForgotPassword,
  VerifyEmail,
  Admin,
  Home,
  ResetPassword,
  Footer
} from "./Components";
import { EditProfile } from "./Components/CreateProfileSteps";
import { HOTJAR_SETTINGS, GOOGLE_ANALYTICS_TRACK_ID } from "./Constants";
import ReactGA from "react-ga4";

export default function Hyfen8Client() { 
    const { initHotjar } = useHotjar();
    useEffect(() => {
      ReactGA.initialize(GOOGLE_ANALYTICS_TRACK_ID);
      initHotjar(HOTJAR_SETTINGS.id, HOTJAR_SETTINGS.version, false);
    }, [initHotjar]);
  
  return (
    <BrowserRouter>
      <Nav></Nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Section />} >
          <Route path=":name"  element={<Section />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="my-profile" element={<EditProfile />} />
        <Route path="admin" element={<Admin />} />
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  );
}