import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const [isCurrentPathLogin, setIsCurrentPathLogin] = useState(false);
  const [isCurrentPathForgotPassword, SetIsCurrentPathForgotPassword] = useState(false);

  // hide the footer textn if the current page is login
  useEffect(() => {
    setIsCurrentPathLogin(location.pathname === "/login");
    SetIsCurrentPathForgotPassword(location.pathname === "/forgot-password");
  }, [location])

  return (
    isCurrentPathLogin 
      ? null 
      : isCurrentPathForgotPassword 
        ? null 
        : (
          <div>
            <footer>
              <div className="container">
                <hr />
                We treat your data with the utmost care, integrity and confidentiality. Once you have established your profile, you can choose to delete it - along with all your data - at any time. 
              </div>
            </footer>
          </div>
        )
  )
}
