import { HYFEN8_PORTAL_BACKEND_URL } from "./Constants";
import { getAuthHeader, saveItemInStorage, getItemFromStorage, removeAssessmentIds, getLoggedInUser, StorageLocation, getStorageLocation } from "./utils";

export interface Answer {
  name: string;
  type: string;
  question: string;
  answer?: string | number;
  "sub-questions"?: Answer[];
}

export interface RegisterUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  answers: Answer[];
}

export interface LoginUser {
  email: string;
  password: string;
}

export interface LoginResponse {
  user: LoginUserResponse;
  tokens: LoginTokenResponse;
}

export interface LoginUserResponse {
  role: "admin" | "user";
  isEmailVerified: boolean;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  id: string;
  sectionAnswers: SectionAnswerModel[];
  answers: Answer[];
  reportSent: boolean;
  notifyOfNewAssessments: boolean
  isAssessmentNew: boolean
}

export interface QueryUserResponse {
  limit: number;
  page: number;
  totalPages: number;
  totalResults: number;
  results: QueryUser[];
}

export interface QueryUser {
  id: string;
  isEmailVerified: boolean;
  firstName: string;
  email: string;
  answers: Answer[];
  sectionAnswers: SectionAnswerModel[];
}

export interface LoginTokenResponse {
  access: TokenDetails;
  refresh: TokenDetails;
}

export interface TokenDetails {
  token: string;
  expires: string;
}

export interface SectionModel {
  number: number;
  name: string;
  description: string;
  themeColour: string;
  subSections: SubSectionModel[];
}

export interface SubSectionModel {
  name: string;
  questions: QuestionModel[];
}

export interface QuestionModel {
  id: string;
  question: string;
  type: string;
  required?: boolean;
  choices?: string[];
  conditionalQuestions?: ConditionalQuestionModel[];
}

export interface ConditionalQuestionModel extends QuestionModel {
  conditionalValue: string;
}

export enum SectionAnswerStatus {
  Complete = "Complete",
  InProgress = "InProgress",
}

export interface SectionAnswerModel {
  number: number;
  name: string;
  status: SectionAnswerStatus;
  subSections: SubSectionAnswerModel[];
}

export interface SubSectionAnswerModel {
  name: string;
  answers: QuestionAnswerModel[];
}

export interface QuestionAnswerModel {
  id: string;
  question: string;
  answer: string;
}

export interface ApiError {
  code: number;
  message: string;
  stack?: string;
}
export const isApiError = (object: any): object is ApiError =>
  object && "code" in object && "message" in object;

export const isError = (object: any): object is Error =>
  "name" in object && "message" in object;

interface Dictionary<T> {
  [Key: string]: T;
}

const errorToApiError = (error: unknown): ApiError => {
  let errorResponse: ApiError;
  if (isError(error)) {
    errorResponse = {
      code: 503,
      message: error.message,
      stack: error.stack,
    };
  }
  errorResponse = {
    code: -1,
    message: String(error),
  };
  return errorResponse;
};

export class ApiClient {
  basePath: string;
  constructor() {
    const apiVersion = "v1";
    this.basePath = `${HYFEN8_PORTAL_BACKEND_URL}/${apiVersion}`;
  }

  /**
   * Get base path URL for the API
   * @returns Base path URL
   */
  getBasePath() {
    return this.basePath;
  }

  /**
   * Check whether profile already exists for given email address
   * @param {String} email Email Address
   */
  async checkEmail(email: string): Promise<string | null> {
    try {
      const url = new URL(`${this.basePath}/auth/check-email`);
      const params = { email };
      url.search = new URLSearchParams(params).toString();
      const headers = {
        ...(await getAuthHeader()),
        Accept: "application/json",
      };
      const response = await fetch(url.toString(), {
        method: "GET",
        headers: headers,
      });
      const status = response.status;
      if (status === 409) {
        return "Email address is already in use";
      }
      if (status === 400) {
        return "Email address is invalid";
      }
      if (status > 400) {
        return "Please use a different email address";
      }
    } catch (error: any) {
      return "Please use a different email address";
    }
    return null;
  }

  // Country  and regions list based on ISO 3166 https://www.iso.org/iso-3166-country-codes.html
  regionChoices = [
    {
      Australia: [
        "Australian Capital Territory",
        "New South Wales",
        "Northern Territory",
        "Queensland",
        "South Australia",
        "Tasmania",
        "Victoria",
        "Western Australia",
      ],
    },
    {
      "All Countries": [
        "Algeria",
        "Åland Islands",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo, Democratic Republic",
        "Congo, Rebuplic",
        "Cook Islands",
        "Costa Rica",
        "Côte d'Ivoire",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People's Republic",
        "Korea, Republic",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "North Macedonia",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Réunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States Minor Outlying Islands",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Virgin Islands, British",
        "Virgin Islands, U.S.",
        "Wallis and Futuna",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
    },
  ];

  startYear = 1930;
  currentYear = new Date().getFullYear();
  years = Array.from(
    { length: this.currentYear - this.startYear + 1 },
    (_, i) => this.currentYear - i
  );

  async getRegistrationDetails(): Promise<Dictionary<any>> {
    return {
      "user-name": {
        question: "Your name",
        placeholder: "Enter your name",
        required: true,
        type: "text",
      },
      "user-designation": {
        question: "Your role",
        placeholder: "E.g. Chief Executive Officer, Head of Finance, etc.",
        required: true,
        type: "text",
      },
      "company-name": {
        question: "Organisation name",
        placeholder: "E.g. ABC Pty Ltd",
        required: true,
        type: "text",
      },
      "company-reg-number": {
        question: "Business registration no.",
        placeholder: "E.g. ABN or NZBN or EIN",
        required: true,
        type: "text",
      },
      "industry": {
        question: "Name the industries your business competes in",
        required: true,
        type: "select",
        multi: true,
        choices: [],
        placeholder: "You may select multiple industries. i.e. manufacturing, food & beverage, etc.",
      },
      "employee-count": {
        question:
          "Number of people employed by your business",
        required: true,
        type: "slider",
        scale: "log",
        allowOverflow: true,
        marks: [
          { value: 0, label: "1" },
          { value: 1, label: "10" },
          { value: 2, label: "100" },
          { value: 3, label: "1,000" },
          { value: 4, label: "10,000+" },
        ],
      },
    };
  }
  async register(user: RegisterUser): Promise<LoginUserResponse | ApiError> {
    try {
      const url = `${this.basePath}/auth/register`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(user),
      });
      return await response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async login(user: LoginUser): Promise<LoginResponse | ApiError> {
    try {
      const url = `${this.basePath}/auth/login`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(user),
      });
      return await response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async logout(refreshToken: string): Promise<ApiError | undefined> {
    try {
      const url = `${this.basePath}/auth/logout`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ refreshToken }),
      });
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async refreshToken(
    refreshToken: string
  ): Promise<LoginTokenResponse | ApiError> {
    try {
      const url = `${this.basePath}/auth/refresh-tokens`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ refreshToken }),
      });
      return await response.json();
    } catch (error: any) {
      // Assignment required for type narrowing
      return errorToApiError(error);
    }
  }

  // the assessment id will be used to uniquely identify each set of sections 
  // and will changw only when an assessment is reset
  async getNewAssessmentId() {
    const newAssessmentId = getItemFromStorage('new_assessment_id')
    if(newAssessmentId == null){
      try {
        const url = `${this.basePath}/sections/assessmentId`;
        const  headers = {
          ...(await getAuthHeader()),
          "content-type": "application/json",
          Accept: "application/json",
        };
        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
        if (isErrorStatus(response.status)) {
          return await response.json();
        } else {
          const assessmentId = await response.json();
          let remember = getStorageLocation() === StorageLocation.Local
          saveItemInStorage("new_assessment_id", assessmentId.getAssessmentId, remember)
        }
        return;
      } catch (error) {
        return errorToApiError(error);
      }            
    }
  }
  async getLatestAssessmentId() {
    let loggedInUser:any = await getLoggedInUser()
    if(!loggedInUser.isAssessmentNew){
      const latestAssessmentId = getItemFromStorage('latest_assessment_id')
      if(latestAssessmentId == null){
        try { 
          const url = `${this.basePath}/users/latest-assessmentId`;
          const headers = {
            ...(await getAuthHeader()),
            "content-type": "application/json",
            Accept: "application/json",
          };
          const response = await fetch(url, {
            method: "GET",
            headers: headers,
          });
          if (isErrorStatus(response.status)) {
            return await response.json();
          } else {
            const assessmentId = await response.json();
            if (assessmentId.assessmentId != null) {
              let remember = getStorageLocation() === StorageLocation.Local
              saveItemInStorage("latest_assessment_id", assessmentId.assessmentId, remember)
            } else {
              await this.getNewAssessmentId()
            }
          }
          return;
        } catch (error) {
          return errorToApiError(error);
        }   
      }                   
    } else {
      await this.getNewAssessmentId()
    }
  }
  async saveAssessment(sectionId: number, questionairreResponse: SectionAnswerModel) {
    try {
      await this.getLatestAssessmentId()
      const newAssessmentId = getItemFromStorage('new_assessment_id')
      const latestAssessmentId = getItemFromStorage('latest_assessment_id')
      
      let assessmentId
      if ( latestAssessmentId != null ) {assessmentId = latestAssessmentId}
      else if (newAssessmentId != null) {assessmentId = newAssessmentId}

      const url = `${this.basePath}/sections/number/${sectionId} `;

      const payload = {
        assessmentId: assessmentId,
        ...questionairreResponse
      }
      const headers = {
        ...(await getAuthHeader()),
        "content-type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      let loggedInUser:any = await getLoggedInUser()
      if(loggedInUser.isAssessmentNew){
        loggedInUser.isAssessmentNew = false
        let remember = getStorageLocation() === StorageLocation.Local
        saveItemInStorage('user', JSON.stringify(loggedInUser), remember)
        this.isAssessmentNew(false)
      }
      return;
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async getSavedSectionData( sectionId ) {
    let loggedInUser:any = await getLoggedInUser()
    if(!loggedInUser.isAssessmentNew){
      try {
        await this.getLatestAssessmentId()
        const newAssessmentId = getItemFromStorage('new_assessment_id')
        const latestAssessmentId = getItemFromStorage('latest_assessment_id')

        let assessmentId
        if ( latestAssessmentId != null ) {assessmentId = latestAssessmentId}
        else if (newAssessmentId != null) {assessmentId = newAssessmentId}

        const url = `${this.basePath}/sections/assessmentId/${assessmentId}/number/${sectionId}`;
        
        const headers = {
          ...(await getAuthHeader()),
          "content-type": "application/json",
          Accept: "application/json",
        };
        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
        if (isErrorStatus(response.status)) {
            return await response.json();
        }
        const questions = await response.json();
        return questions;
      } catch (error) {
        return errorToApiError(error);
      }
    }
  }
  async resetAssessment() {
    try {
      await removeAssessmentIds()
      await this.getNewAssessmentId()
      let loggedInUser:any = await getLoggedInUser()
      loggedInUser.sectionAnswers = []
      loggedInUser.isAssessmentNew = true
      loggedInUser.reportSent = false
      loggedInUser.sectionAnswers = []
      let remember = getStorageLocation() === StorageLocation.Local
      saveItemInStorage('user', JSON.stringify(loggedInUser), remember)
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async notifyOfnewAssessment(notifyOfNewAssessments: Boolean): Promise<ApiError> {
    try {
      const url = `${this.basePath}/users`;

      const headers = {
        ...(await getAuthHeader()),
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({notifyOfNewAssessments}),
      });
      return await response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async isAssessmentNew( isAssessmentNew: Boolean ): Promise<LoginUserResponse | ApiError> {
    try {
      const url = `${this.basePath}/users`;
      const headers = {
        ...(await getAuthHeader()),
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ isAssessmentNew }),
      });
      let loggedInUser:any = await getLoggedInUser()
      let remember = getStorageLocation() === StorageLocation.Local
      saveItemInStorage('user', JSON.stringify(loggedInUser), remember)
      return await response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async getNewSectionData(sectionNumber: number): Promise<SectionModel | ApiError> {
    try {
      const url = `${this.basePath}/sections/number/${sectionNumber}`;

      const headers = {
        ...(await getAuthHeader()),
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const questions = await response.json();
      return questions;
    } catch (error: any) {
      return errorToApiError(error);
    }
  }

  async getReport(email:string): Promise<void | ApiError>{
    try {
      const url = `${this.basePath}/sections/assessment-report`;
      const headers = {
        ...(await getAuthHeader()),
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ email }),
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      return;
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async updateReportSent( reportSent:Boolean ): Promise<LoginUserResponse | ApiError> {
    try {
      const url = `${this.basePath}/users`;
      const headers = {
        ...(await getAuthHeader()),
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ reportSent }),
      });
      return await response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }

  async updateUserProfile( answers: Answer[]): Promise<LoginUserResponse | ApiError> {
    try {
      let name
      let industryType 
      let organizationName
      let designation

      const url = `${this.basePath}/users`;
      Object.entries(answers).map(async ([key, value]) => {
        if (value.name==='user-name') {
          name = value.answer
        } else if(value.name==='industry') {
          industryType = value.answer

          industryType.forEach((element, index) => {
            if(typeof element === 'object') {
              industryType[index] = element["key"]
            }
          });
        } else if(value.name==='company-name') {
          organizationName = value.answer
        } else if(value.name==='user-designation') {
          designation = value.answer
        }
      });

      const headers = {
        ...(await getAuthHeader()),
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ answers, name, industryType, organizationName, designation}),
      });
      return await response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async deleteUser(userID: string): Promise<ApiError | undefined> {
    try {
      const url = `${this.basePath}/users/${userID}`;

      const headers = {
        ...(await getAuthHeader()),
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      return;
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async sendVerificationEmail(email: string) {
    try {
      const url = new URL(`${this.basePath}/auth/forgot-password/`);

      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ email }),
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      return;
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async resetPassword(token: string, password: string) {
    try {
      const url = new URL(`${this.basePath}/auth/reset-password/`);
      const params = { token };
      url.search = new URLSearchParams(params).toString();

      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      await fetch(url.toString(), {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ password }),
      });
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async changePassword(currentPassword: string, newPassword: string) {
    try {
      const url = new URL(`${this.basePath}/auth/change-password/`);

      const headers = {
        ...(await getAuthHeader()),
        "content-type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ currentPassword, newPassword }),
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      return;
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async verifyEmail(token: string): Promise<LoginResponse | ApiError> {
    try {
      const url = new URL(`${this.basePath}/auth/verify-email/`);
      const params = { token };
      url.search = new URLSearchParams(params).toString();

      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url.toString(), {
        method: "GET",
        headers: headers,
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      return response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async setReminder(date: Date): Promise<ApiError | undefined> {
    try {
      const url = new URL(
        `${this.basePath}/sections/set-reminder/${date.toISOString()}`
      );
      const params = { date: date.toISOString() };
      url.search = new URLSearchParams(params).toString();

      const headers = {
        ...(await getAuthHeader()),
        "content-type": "application/json",
        Accept: "application/json",
      };
      const result = await fetch(url.toString(), {
        method: "GET",
        headers: headers,
      });
      if (isErrorStatus(result.status)) {
        return await result.json();
      }
      return;
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async getUsers(
    limit: number = 10,
    page: number = 1,
    role: string = "user"
  ): Promise<ApiError | QueryUserResponse> {
    try {
      const url = new URL(`${this.basePath}/users`);
      const params = { limit: limit.toString(), page: page.toString(), role };
      url.search = new URLSearchParams(params).toString();
      const headers = {
        ...(await getAuthHeader()),
        "content-type": "application/json",
        Accept: "application/json",
      };
      const result = await fetch(url.toString(), {
        method: "GET",
        headers: headers,
      });
      return await result.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async downloadSurveyReport(userId: string): Promise<ApiError | Blob> {
    try {
      const url = new URL(`${this.basePath}/users/downloadSurvey/${userId}`);
      const headers = {
        ...(await getAuthHeader()),
        "content-type": "application/json",
        Accept: "application/json",
      };
      const result = await fetch(url.toString(), {
        method: "GET",
        headers: headers,
      });
      if (isErrorStatus(result.status)) {
        return await result.json();
      }
      return await result.blob();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async resendVerificationEmail(email: string): Promise<ApiError | undefined> {
    try {
      const url = `${this.basePath}/auth/resend-verification-email`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ email }),
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      return;
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async downloadPofileFile(userId: string): Promise<ApiError | Blob> {
    try {
      const url = new URL(
        `${this.basePath}/users/downloadProfileFile/${userId}`
      );
      const headers = {
        ...(await getAuthHeader()),
        Accept: "application/json",
      };
      const result = await fetch(url.toString(), {
        method: "GET",
        headers: headers,
      });
      if (isErrorStatus(result.status)) {
        return await result.json();
      }
      return await result.blob();
    } catch (error) {
      return errorToApiError(error);
    }
  }
  async getIndustryList(): Promise<void | ApiError>{
    try {
      const url = `${this.basePath}/users/industry-types`;
      const headers = {
        ...(await getAuthHeader()),
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      return response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }
}

function isErrorStatus(status: number): boolean {
  return status >= 400 && status < 600;
}
