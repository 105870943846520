import { useEffect } from 'react';
import useHotjar from 'react-use-hotjar';
import { getRelativePath } from '../utils';

export default function useHotJarStateChange() {
  const { stateChange, readyState } = useHotjar();
  useEffect(() => {
    if (readyState) {
      stateChange(getRelativePath());
    }

  }, [readyState, stateChange])
}