import { Checkbox, Autocomplete, TextField} from '@mui/material';
import { ReactElement, useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { Control, Controller, FieldValues, UseFormRegister } from 'react-hook-form'
import classNames from "classnames";
import { FileUploader, LogarithmicSlider, CurrencyInput } from '../CustomFields';
import './RenderProfileField.scss'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ApiClient, isApiError } from '../../ApiClient';

interface FieldMapFn {
  (control: Control, questionKey: string, questionValue: any, error: any, options?: any): ReactElement
}

interface FieldMapping {
  radio: FieldMapFn,
  select: FieldMapFn,
  slider: FieldMapFn,
  text: FieldMapFn,
  number: FieldMapFn,
  currency: FieldMapFn,
  file: FieldMapFn,
  textarea: FieldMapFn,
};

type RenderProfileFieldProps = {
  control: Control,
  questionKey: string,
  questionValue: any,
  register: UseFormRegister<FieldValues>,
  error: any, 
  options?: any
}

export default function RenderProfileField({ control, questionKey, questionValue, register, error, options }: RenderProfileFieldProps): JSX.Element {
const [industryAndCategory, setIndustryAndCategory] = useState<any>([]);
const [savedIndustryAndCategory, setSavedIndustryAndCategory] = useState<any>([]); 

  const renderRadio = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <div>
        {questionValue.choices.map((choice: string) => (
          <Form.Check
            type="radio"
            {...register(questionKey, { required: questionValue.required })}
            label={choice}
            value={choice}
            inline={questionValue.inline}
          />
        ))}
        {error ? <div className="error-text">Required</div> : <></>}
      </div>
    );
  };

  useEffect(() => {
    // the password fields re render the page and updates the selected industries as objects
    // these obects can be used as they are
    if(!control._formValues.industry || !(control._formValues.industry.some(industry => industry.category))){
      // will loop for each field, so adding this IF condition will ensure that this will run only for the industry field
      if(questionKey === "industry"){
        // async function within useEffect to ensure that the field updates in real time
        const fetchData = async () => {
          let industryListOptions
          const client = new ApiClient();
          const industryList:any = await client.getIndustryList();
          if (isApiError(industryList)) {
            console.error(industryList);
            return;
          }
        
          // create an object for each industry to use in the frontend
          if(industryList !== undefined){
            industryListOptions = industryList.map((industryListOption) => {
              const industry = industryListOption;
              const category = industryListOption[0].toUpperCase();
  
              return {
                industry: industry,
                category: category,
              };
            });
          }
          
          // create objects for the already selected industries to use in the frontend
          // this is done because the formate saved in the backend and the one used in the frontend is not the asme
          if(control._formValues.industry){
            const savedIndustryAndCategoryArray = control._formValues.industry.map((controlIndustry)=>{
              let industry = controlIndustry.toString().substring(controlIndustry.toString().indexOf('- ') + 1)
              let category = controlIndustry.toString().split(" -", 1)
              industry = String(industry).trim()
              category = String(category).trim()
              return {
                key: category+" - "+industry,
                industry: industry,
                category: category,
                selected: true
              }
            })
            setSavedIndustryAndCategory(savedIndustryAndCategoryArray)
          }
  
          let industryListOptionObjects:any = []
          for (const industryListOption of industryListOptions) {
            industryListOptionObjects.push({
              key: industryListOption.category+" - "+industryListOption.industry,
              industry: industryListOption.industry,
              category: industryListOption.category,
            })
          }
          setIndustryAndCategory(industryListOptionObjects)
        }
        fetchData().catch(console.error); 
      }
    }   
  }, [questionValue, options])

  const renderSelect = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <>
        <Controller
          render={({ field }) => 
            <div>
              <Autocomplete
                onChange={(event, value:any) => {
                  field.onChange(value)
                  value.forEach((selectedValue) => selectedValue.selected=true)
                  setSavedIndustryAndCategory(value)
                }}
                isOptionEqualToValue={(option:any, value:any) => option.key === value.key}
                multiple
                disableCloseOnSelect
                openOnFocus
                disableClearable
                value={savedIndustryAndCategory}
                options={industryAndCategory}
                groupBy={(option)=>option.category}
                getOptionLabel={(option)=>option.industry}
                renderInput={(params) => <TextField {...params} label={questionValue.placeholder} />}
                renderOption={(props, option:any, itemState) => {
                  let currentState = itemState
                  currentState.selected = (savedIndustryAndCategory.some((item)=> item.key === option.category+" - "+option.industry))
                  return <li {...props} key={option.key}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />} 
                      checkedIcon={<CheckBoxIcon />}
                      checked={ currentState.selected }
                    />
                    {option.industry}
                  </li>
                }}
              />
            </div>
          }
          control={control}
          name={questionKey}
          rules={{ required: questionValue.required }}
          />
        {error ? <div className="error-text">Required</div> : <></>}
      </>
    );
  };

  const renderLogSlider = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <>
        <Controller
          render={
            ({ field }) => <LogarithmicSlider
              {...field}
              valueLabelDisplay="auto"
              marks={questionValue.marks}
              min={Math.min(...questionValue.marks.map((m: any) => m.value))}
              max={Math.max(...questionValue.marks.map((m: any) => m.value))}
              allowOverflow={questionValue.allowOverflow}
            />
          }
          control={control}
          name={questionKey}
          defaultValue={Math.min(...questionValue.marks.map((m: any) => m.value))}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </>
    );
  };

  const renderInput = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <div className={`${questionValue.type}`}>
        <Form.Control
          type={questionValue.type}
          {...register(questionKey, { required: questionValue.required })}
          placeholder={questionValue.placeholder}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </div>
    );
  };

  const renderCurrencyInput = (control: Control, questionKey: string, questionValue: any, error: any, options?: any): ReactElement => {
    const currencySymbol = options?.currencySymbol ?? "A$";
    return (
      <div className="d-sm-flex justify-content-sm-end align-items-center">
        <Controller
          control={control}
          name={questionKey}
          render={
            ({ field }) => <CurrencyInput
              {...field}
              currencySymbol={currencySymbol}
              placeholder={questionValue.placeholder}
              allowNegative={questionValue.allowNegative}
            />
          }
          rules={{ required: questionValue.required }}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </div>
    );
  };

  const renderFileInput2 = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <>
        <div className="d-sm-flex">
          <Controller
            control={control}
            name={questionKey}
            render={({ field }) => (
              <FileUploader
                {...field}
                placeholder={questionValue.placeholder}
                accept={questionValue.accept}
              />
            )}
            rules={{ required: questionValue.required }}
          />
        </div>
        {error ? <div className="error-text">Required</div> : <></>}
      </>
    );
  };

  const renderTextArea = (control: Control, questionKey: string, questionValue: any, error: any): ReactElement => {
    return (
      <>
        <Form.Control
          as="textarea"
          type={questionValue.type}
          {...register(questionKey, { required: questionValue.required })}
          placeholder={questionValue.placeholder}
          maxLength={questionValue.maxLength}
        />
        {error ? <div className="error-text">Required</div> : <></>}
      </>
    );
  };

  const fieldMapping: FieldMapping = {
    radio: renderRadio,
    select: renderSelect,
    slider: renderLogSlider,
    text: renderInput,
    number: renderInput,
    currency: renderCurrencyInput,
    file: renderFileInput2,
    textarea: renderTextArea,
  };

  const renderField = (control: Control, questionKey: string, questionValue: any, error: any, options: any): ReactElement => {

    return (
      <div id={`profile-${questionKey}`} key={questionKey} className={classNames("question-container", {
        inline: questionValue.inline,
        "sub-questions": questionValue["sub-questions"],
        error: error,
      })}>
        <div className="question-title mb-3 text-2">
          {questionValue.question}
        </div>
        <div className={`question-field-container ${questionValue.type}`}>
          {
            questionValue["sub-questions"] ?
              Object.entries(questionValue["sub-questions"]).map(([key, value]) => 
              renderField(control, `${questionKey}.${key}`, value, error ? error[key] : null, options))
              : fieldMapping[questionValue.type as keyof FieldMapping](control, questionKey, questionValue, error, options)
          }
        </div>
      </div>
    )
  }

  return renderField(control, questionKey, questionValue, error, options);
}
