import { CustomHelpers, LanguageMessages } from "joi";
import { NavigateFunction } from "react-router-dom";
import ReactGA from "react-ga4";

import {
  ApiClient,
  LoginResponse,
  LoginTokenResponse,
  LoginUserResponse,
  TokenDetails,
} from "./ApiClient";
import {
  ACCESS_TOKEN_KEY,
  HOTJAR_SETTINGS,
  LOGGED_IN_USER_KEY,
  REFRESH_TOKEN_KEY,
  NEW_ASSESSMENT_ID,
  LATEST_ASSESSMENT_ID,
  GOOGLE_ANALYTICS_TRACK_ID
} from "./Constants";
import { hotjar } from "react-hotjar";

export enum StorageLocation {
  Session,
  Local,
}

export async function isAuthenticated(): Promise<boolean> {
  const accessTokenLS = getItemFromStorage(ACCESS_TOKEN_KEY);
  if (accessTokenLS) {
    const accessToken: TokenDetails = JSON.parse(accessTokenLS);
    const expireDate = new Date(accessToken.expires);
    const currentDate = new Date();
    if (expireDate > currentDate) {
      return true;
    }
  }
  const refreshTokenLS = getItemFromStorage(REFRESH_TOKEN_KEY);
  if (!refreshTokenLS) {
    return false;
  }
  const refreshToken: TokenDetails = JSON.parse(refreshTokenLS);
  const expireDate = new Date(refreshToken.expires);
  const currentDate = new Date();
  if (expireDate <= currentDate) {
    return false;
  }
  const client = new ApiClient();
  const newTokens = await client.refreshToken(refreshToken.token);
  if (!("access" in newTokens)) {
    return false;
  }
  saveTokens(newTokens);
  return true;
}

export async function getAuthHeader(): Promise<object> {
  await isAuthenticated();
  const accessTokenLS = getItemFromStorage(ACCESS_TOKEN_KEY);
  if (!accessTokenLS) {
    return {};
  }
  const accessToken: TokenDetails = JSON.parse(accessTokenLS);
  return { Authorization: `Bearer ${accessToken.token}` };
}

export function getStorageLocation(): StorageLocation {
  return sessionStorage.getItem(LOGGED_IN_USER_KEY)
    ? StorageLocation.Session
    : StorageLocation.Local;
}

export function saveLogin(
  loginResult: LoginResponse,
  remember: boolean | null = null
) {
  if (remember === null) {
    remember = getStorageLocation() === StorageLocation.Local;
  }
  removeLogin();
  const user = loginResult.user;
  if (!("sectionAnswers" in user)) {
    user.sectionAnswers = [];
  }
  saveUser(user, remember);
  saveTokens(loginResult.tokens, remember);
}

export function saveUser( user, remember: boolean | null = null ) {
  if (remember === null) {
    remember = getStorageLocation() === StorageLocation.Local;
  }
  user.answers = user.answers.map((ans) => {
    if (ans.type === "file") {
      ans.answer = ans.answer ? "uploaded" : undefined;
    }
    return ans;
  });
  saveItemInStorage(LOGGED_IN_USER_KEY, JSON.stringify(user), remember);
}

export function saveTokens(
  loginTokenResult: LoginTokenResponse,
  remember: boolean | null = null
) {
  if (remember === null) {
    remember = getStorageLocation() === StorageLocation.Local;
  }
  const access_token = loginTokenResult.access;
  saveItemInStorage(ACCESS_TOKEN_KEY, JSON.stringify(access_token), remember);
  const refresh_token = loginTokenResult.refresh;
  saveItemInStorage(REFRESH_TOKEN_KEY, JSON.stringify(refresh_token), remember);
}

export function removeLogin() {
  removeItemFromStorage(LOGGED_IN_USER_KEY);
  removeItemFromStorage(ACCESS_TOKEN_KEY);
  removeItemFromStorage(REFRESH_TOKEN_KEY);
  removeItemFromStorage(NEW_ASSESSMENT_ID);
  removeItemFromStorage(LATEST_ASSESSMENT_ID);
}

export function removeAssessmentIds() {
  removeItemFromStorage(NEW_ASSESSMENT_ID);
  removeItemFromStorage(LATEST_ASSESSMENT_ID);
}

export function saveItemInStorage(key: string, value: string, isLocal = true) {
  if (isLocal) {
    localStorage.setItem(key, value);
  } else {
    sessionStorage.setItem(key, value);
  }
}

export function getItemFromStorage(key: string) {
  return sessionStorage.getItem(key) ?? localStorage.getItem(key);
}

export function removeItemFromStorage(key: string) {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
}

export function getLoggedInUser(): LoginUserResponse | null {
  const userLS = getItemFromStorage(LOGGED_IN_USER_KEY);
  if (!userLS) {
    return null;
  }
  return JSON.parse(userLS) as LoginUserResponse;
}

export function toBase64(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getObjectHash(object: object): number {
  const objString = JSON.stringify(object);
  let hash = 0,
    i,
    chr;
  for (i = 0; i < objString.length; i++) {
    chr = objString.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function redirectToLogin(navigate: NavigateFunction) {
  (async () => {
    if (!(await isAuthenticated())) {
      navigate("/login");
    }
  })();
}

export function redirectToRegister(navigate: NavigateFunction) {
  (async () => {
    if (!(await isAuthenticated())) {
      navigate("/signup");
    }
  })();
}

export function redirectToHome(navigate: NavigateFunction) {
  (async () => {
    if (await isAuthenticated()) {
      navigate("/");
    }
  })();
}

export function passwordValidation(value: any, helpers: CustomHelpers) {
  if (value.length < 8) {
    return helpers.message(
      "Password must be at least 8 characters" as unknown as LanguageMessages
    );
  }
  if (!value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
    return helpers.message(
      "Password must contain at least 1 letter and 1 number" as unknown as LanguageMessages
    );
  }
  return value;
}

export function getRelativePath() {
  return window.location.pathname + window.location.search;
}

export function gaEventTrack (category: string, action: string, label: string) {
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACK_ID);

  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}

export function hjEventTrack (action: string) {
  hotjar.initialize(HOTJAR_SETTINGS.id, HOTJAR_SETTINGS.version);
  hotjar.event(action);
}
