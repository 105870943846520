import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ApiClient, isApiError, LoginUser } from '../ApiClient';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { gaEventTrack, hjEventTrack, redirectToHome, saveLogin } from '../utils';
import { useAuthenticationRedirect, useHotJarStateChange } from '../CustomHooks';

export default function Login() {

  useAuthenticationRedirect(false);
  const navigate = useNavigate();
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [serverErrorMessage, setServerErrorMessage] = useState<string>();
  const [passwordShown, setPasswordShown] = useState(false)
  useHotJarStateChange();

  useEffect(() => {
    document.title = 'Log in';
  }, [])

  const loginSchema = Joi.object().keys({
    email: Joi.string().required().email({ tlds: { allow: false } }).messages({
      'string.email': "Invalid email address",
      'string.empty': "Required",
    }),
    password: Joi.string().min(8).required().messages({
      'string.empty': "Required",
      'string.min': "Password must be at least {#limit} characters",
    }),
    remember: Joi.boolean(),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: joiResolver(loginSchema)
  });
  const onSubmit = (data: any) => {
    handleLogin({ email: data.email, password: data.password }, data.remember);
    hjEventTrack('login-button-click');
    gaEventTrack("login page","login button clicked","button")
  }

  const handleChange = () => {
    setServerErrorMessage("")
  }

  const handleLogin = async (loginUser: LoginUser, remember: boolean) => {
    setIsSigningIn(true);
    const client = new ApiClient();
    const result = await client.login(loginUser);
    if (isApiError(result)) {
      setServerErrorMessage(result.message);
      setIsSigningIn(false);
      return;
    }
    if(!(result.user.answers?.length > 0)){
      saveLogin(createAnswersObject(result), remember);
    }
    saveLogin(result, remember);
    if (result.user.role === "admin") {
      navigate("/admin");
    }
    else {
      redirectToHome(navigate);
    }
    setIsSigningIn(false);
  };

  // save user.answers in correct format if the login is successful and user.answers does not have content
  const createAnswersObject = (userResult) => {
    let userNameObj={
      "name": "user-name", 
      "type": "text", 
      "question": "Your name", 
      "answer": (userResult.user.name)
    }
    let companyNameObj={
      "name": "company-name",
      "type": "text",
      "question": "Organisation name",
      "answer": (userResult.user.organizationName)
    }

    userResult.user.answers.push(userNameObj)
    userResult.user.answers.push(companyNameObj)
    return userResult
  }

  return (
    <div>
      <div className="login-page">
        <div className="container">
          <h1 className="text-1 mx-auto">Log in</h1>
          <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column form-gap mx-auto">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="text-2">Username</Form.Label>
              <Form.Control {...register("email")}
                onInput={handleChange}
                placeholder="Your email is your username" />
              <div className="error-text">{errors.email?.message}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="text-2">Password</Form.Label>
              <div className='wr-pwd-field'>
              <Form.Control {...register("password")}
                type={passwordShown ? "text" : "password"}
                onInput={handleChange}
                placeholder="Password" />
                <Button variant="light" className="button-outline show-password" id="showPassword" onClick={e => {
                  e.preventDefault()
                  setPasswordShown((prev) => !prev);
                }}>{passwordShown ? 'Hide' : 'Show' }</Button>
              </div>
              <div className="error-text">{errors.password?.message}</div>
              {serverErrorMessage ? <div className="error-text">{serverErrorMessage}</div> : ""}
            </Form.Group>

            <div className="d-lg-flex flex-lg-row justify-content-lg-between align-items-lg-center">
              <div className="wr-remember-me">
                <Form.Group className="remember-me" controlId="formBasicCheckbox">
                  <Form.Check {...register("remember")} type="checkbox" label="Remember me" />
                </Form.Group>
                <div className='mx-4 seperator'>|</div>
                <Link id="forgot-password-link" to="/forgot-password">Forgot password?</Link>
              </div>
              <Button variant="primary" type="submit" disabled={isSigningIn}>Log in</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}