import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getItemFromStorage, isAuthenticated, removeLogin } from "../utils";
import  Button  from "react-bootstrap/Button";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../Assets/hyfen8-logo.png'
import './Nav.css'
import { ApiClient } from "../ApiClient";
import { useNavigate } from 'react-router-dom';
import { HYFEN8_WEBSITE_URL, REFRESH_TOKEN_KEY } from "../Constants";

export default function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCurrentPathMyProfile, setIsCurrentPathMyProfile] = useState(false);
  const [isCurrentPathDashboard, setIsCurrentPathDashboard] = useState(false);
  const [isCurrentPathLogin, setIsCurrentPathLogin] = useState(false);

  useEffect(() => {
    (async () => setIsLoggedIn(await isAuthenticated()))()
  }, [location])

  const handleLogout = async () => {
    const refreshTokenLS = getItemFromStorage(REFRESH_TOKEN_KEY);
    if (refreshTokenLS) {
      const client = new ApiClient();
      await client.logout(refreshTokenLS);
    }
    removeLogin();
    navigate("/login");
  }

  // disable the nav bar button if the current page is the same as the button link
  useEffect(() => {
    setIsCurrentPathMyProfile(location.pathname === "/my-profile");
    setIsCurrentPathDashboard(location.pathname === "/");
    setIsCurrentPathLogin(location.pathname === "/login");
  }, [location])

  return (
    <div className="navbar-area">
      <div className="container-fluid">
        <Navbar collapseOnSelect expand="lg">
          <Container>
            <Navbar.Brand target="_blank" href={HYFEN8_WEBSITE_URL} id='logo-container' className="logo-brand"><img src={logo} alt='Hyfen8 logo' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"><span className="navtoggle-text">Menu</span></Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav" className="d-lg-flex justify-content-lg-end">
            <nav className="me-auto me-lg-0">
                {
                  isLoggedIn ?
                  <Button disabled={isCurrentPathDashboard} onClick={() => navigate("/")} variant="light" className={`button-outline ${isCurrentPathDashboard ? 'active' : ''}`}><span>Dashboard</span></Button> :
                    null
                }
                {
                  isLoggedIn ?
                    <Button disabled={isCurrentPathMyProfile} onClick={() => navigate("/my-profile")} variant="light" className={`button-outline me-4 ${isCurrentPathMyProfile ? 'active' : ''}`}><span>My Profile</span></Button> :
                    <Button disabled={isCurrentPathLogin} variant="light" className={`button-outline ${isCurrentPathLogin ? 'active' : ''}`} id='nav-login-button' href="/login"><span>Log in</span></Button>
                }
                {
                  isLoggedIn ?                    
                    <Button variant="light" className="pill-button logout" id='nav-logout-button' onClick={handleLogout}>Log out</Button> :
                    <Button target="_blank" onClick={() => navigate(HYFEN8_WEBSITE_URL+"/signup")} variant="light" className='pill-button'>Sign Up</Button>
                }
              </nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}
