import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export default function useQuery() {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search)
    const paramsObject: { [key: string]: any } = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    return paramsObject;
  }, [search]);
}