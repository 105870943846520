import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { gaEventTrack, getLoggedInUser, hjEventTrack, saveUser, StorageLocation, getStorageLocation  } from "../utils";
import "./Home.css";
import { SectionAnswerStatus, ApiClient, isApiError  } from "../ApiClient";
import { SECTIONS, ACTIVE_ASSESSMENTS } from "../Constants";
import { useAuthenticationRedirect } from "../CustomHooks";
import { useForm } from "react-hook-form";
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';  
import { Link } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';

type SectionWithStatusType = {
  number: number;
  name: string;
  colour: any;
  status?: SectionAnswerStatus;
}

export default function Home() {
  useAuthenticationRedirect();
  const [user] = useState(getLoggedInUser());
  const [sectionsWithStatus, setSectionsWithStatus] = useState<SectionWithStatusType[]>([]);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isAssessmentComplete, setIsAssessmentComplete] = useState(false);
  const [isReportRequested, setIsReportRequested] = useState(false);
  const [notifyWhenNewAssessment, setNotifyWhenNewAssessment] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showResendModal, setShowResendModal] = useState(false);
  const handleCloseResendModal = () => setShowResendModal(false);
  const handleShowResendModal = () => setShowResendModal(true);

  // saved an environment variables
  const [availableSections] = useState(ACTIVE_ASSESSMENTS as string[]);

  useEffect(() => {
    document.title = 'Hyfen8 dashboard';

    if (!user) {
      return;
    }

    if(user.sectionAnswers){
      let sectionsWithStatus: SectionWithStatusType[] = [];
      SECTIONS.forEach((section) => {
        const sectionAnswers = user.sectionAnswers.find(
          (ans) => ans.number === section.number
        );
        sectionsWithStatus.push({
          ...section,
          status: sectionAnswers ? sectionAnswers.status : undefined,
        });
      });
      setSectionsWithStatus(sectionsWithStatus);
    }

    if(!user.isAssessmentNew){
      const client = new ApiClient();
      let sectionArray:any = []
      let sectionNumbers:any  =[]

      const fetchData = async () => {
        (SECTIONS.map(section => availableSections.includes(section.name.toLowerCase())?sectionNumbers.push(section.number):null))

        for (let index = 1; index < 9; index++) {
          if(sectionNumbers.includes(index)){
            let getSavedSectionDataResult = await client.getSavedSectionData(index)
            if(getSavedSectionDataResult.message !==  "Section not found"){
              sectionArray.push(getSavedSectionDataResult)
            }
          }
        }
        user.sectionAnswers=(sectionArray)
        let remember = getStorageLocation() === StorageLocation.Local
        saveUser(user, remember)
        setIsAssessmentComplete((user.sectionAnswers.map((section) => section.status === "Complete")).filter(Boolean).length === availableSections.length)
        setIsReportRequested(user.reportSent)
        setIsProfileComplete(user.answers.length === 6);
        setNotifyWhenNewAssessment(user.notifyOfNewAssessments)
        resetSectionView()
      }

      fetchData().catch(console.error); 
    }

    let remember = getStorageLocation() === StorageLocation.Local
    saveUser(user, remember)
    resetSectionView()
    if(user.sectionAnswers && user.sectionAnswers.length>0){
      setIsAssessmentComplete((user.sectionAnswers.map((section) => section.status === "Complete")).filter(Boolean).length === availableSections.length)
    }
    setIsReportRequested(user.reportSent)
    setIsProfileComplete(user.answers.length === 6);
    setNotifyWhenNewAssessment(user.notifyOfNewAssessments)
  }, [user, availableSections]);

  const handleNotificationReminder = async (notify) => {
    const user = getLoggedInUser();
    const client = new ApiClient();
    const notifyOfnewAssessmentResult = await client.notifyOfnewAssessment(notify.target.checked);
    setNotifyWhenNewAssessment(!notify.target.checked)
    if (isApiError(notifyOfnewAssessmentResult)) {
      return;
    }
    let userData = {...user}
    userData.notifyOfNewAssessments = notify.target.checked
    
    let remember = getStorageLocation() === StorageLocation.Local
    saveUser(userData, remember)
  };

  const notificationSchema = Joi.object().keys({
    remember: Joi.boolean(),
  });

  const { register} = useForm({
    resolver: joiResolver(notificationSchema)
  });

  const restartAssessment = async () => {
    const user = getLoggedInUser();
    if (user === null) {
      console.error("No logged in user");
      return;
    }
    const client = new ApiClient();

    const result = await client.resetAssessment();
    if (isApiError(result)) {
      console.error(result);
      return;
    }

    const updateReportSentResult = await client.updateReportSent(false);
    if (isApiError(updateReportSentResult)) {
      console.error(updateReportSentResult);
      return;
    }

    const updateIsAssessmentNew = await client.isAssessmentNew(true);
    if (isApiError(updateIsAssessmentNew)) {
      console.error(updateIsAssessmentNew);
      return;
    }
    user.sectionAnswers = []
    user.isAssessmentNew = true
    user.reportSent = false
    setIsAssessmentComplete(false)
    setIsReportRequested(false)
    let remember = getStorageLocation() === StorageLocation.Local
    saveUser(user, remember)
    handleClose()
    resetSectionView()
  };

  // will update the sections shown on the dashboard
  const resetSectionView = async () => {
    const userData = getLoggedInUser()
    let sectionsWithStatus: SectionWithStatusType[] = [];
    if (!userData) {
      return;
    }
      SECTIONS.forEach((section) => {
        if(userData.sectionAnswers && userData.sectionAnswers.length > 0){
          const sectionAnswers = userData!.sectionAnswers.find((ans) => ans.number === section.number);
          sectionsWithStatus.push({
            ...section,
            status: sectionAnswers ? sectionAnswers.status : undefined,
          });
        } else {
          sectionsWithStatus.push({
            ...section,
            status: undefined
          });
        }
      });
      setSectionsWithStatus(sectionsWithStatus);
  }

  const getReport = async () => {
    const user = getLoggedInUser();
    if (user === null) {
      console.error("No logged in user");
      return;
    }
    
    const client = new ApiClient();
    const getReportResult = await client.getReport(user.email);
    if (isApiError(getReportResult)) {
      console.error(getReportResult);
      return;
    }

    const updateReportSentResult = await client.updateReportSent(true);
    if (isApiError(updateReportSentResult)) {
      console.error(updateReportSentResult);
      return;
    }
    user.reportSent =true
    let remember = getStorageLocation() === StorageLocation.Local
    saveUser(user, remember)
    setIsReportRequested(true)
    const ButtonName = isReportRequested ? "Resend my Hyfen8 Report" : "Get my hyfen8 report";
    gaEventTrack("dashboard page",`${ButtonName} clicked`,"button");
    hjEventTrack(`${ButtonName} button clicked`);
  };

  const handleResendReport = async () => {
    getReport()
    handleShowResendModal()
  }

  return (
    <div className="resume-questionnaire-page">
      <div className="container">
        <div className="content">
          <div className="title-container">
            <div className="header">
              <h1 className="head-1">{user?.name},</h1>
              {isReportRequested ? (
                <h1 className="head-1">congratulations!</h1>
              ) : (
                <h1 className="head-1">welcome.</h1>
              )}
            </div>
            {isReportRequested ? (
              <p className="head-2">
                Your Hyfen8 report is on its way to your inbox.
              </p>
            ) : (
              <p className="head-2">
                Let’s set up your profile to get started or complete any of the
                available assessments.
              </p>
            )}
            <div className="mt-5">
              {isReportRequested ? (
                <div>
                  <p className="text-5 mt-4 mb-5">
                    Remember to check your spam folder if you do not receive it
                    after a few minutes.
                  </p>
                  <p className="text-5 mt-4 mb-5">
                    We are working hard to make more Hyfen Eight<sup>&#174;</sup> intangible
                    assessments available for you. Get notified as soon as they are released by ticking the "Notify me when new assessments are available" checkbox.
                  </p>
                  <p className="text-5 mt-4 mb-5">
                    In the meantime, feel free to retake your existing assessments
                    if your business has evolved and changed.
                  </p>
                </div>
              ) : (
                <p className="text-5 mt-4 mb-5">
                  Your free Hyfen8<sup>&#174;</sup> report will be available once you’ve completed
                  your profile and all available assessments.
                </p>
              )}
            </div>
          </div>

          <div className="action-container">
            <div className="button-section">
              {isReportRequested ? (
                <Button onClick={handleShow} variant="light" className="pill-button">
                  {" "}
                  Restart Assessments{" "}
                </Button>
              ) : (
                <Button href="/my-profile" variant="light" className="pill-button">
                  {isProfileComplete ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <CheckIcon className="me-2" />
                      My Profile Completed
                    </div>
                  ) : (
                    "Complete my profile"
                  )}
                </Button>
              )}

              <Button
                disabled={!isProfileComplete || !isAssessmentComplete}
                onClick={!isReportRequested ? getReport : handleResendReport}
                variant="primary"
              >
                {isReportRequested
                  ? "Resend my Hyfen8 Report"
                  : "Get my hyfen8 report"}
              </Button>
            </div>
          </div>

          <div className="progress-container">
            <div className="assessments-status">
              <h2>AVAILABLE ASSESSMENTS:</h2>
              <ul className="available-assessments">
                {sectionsWithStatus
                  .filter((section) =>
                    availableSections.includes(section.name.toLowerCase())
                  )
                  .map((section) => (
                    <li
                      key={section.number}
                      className={`assessment-item ${
                        section.status
                          ? section.status.toLowerCase()
                          : "pending"
                      }`}
                    >
                      <div>
                        {section.status === "Complete" ? (
                          <CheckCircleIcon
                            sx={{ color: section.colour }}
                            fontSize="large"
                          />
                        ) : (
                          <CircleIcon
                            sx={{ color: section.colour }}
                            fontSize="large"
                          />
                        )}
                        <div className="section-name">{section.name}</div>
                      </div>
                      <Link to={`${section.name.toLowerCase()}`}  className="button-outline btn btn-light">
                      {section.status === "Complete"
                        ? "View"
                        : section.status === "InProgress"
                        ? "Continue"
                        : section.status === undefined
                        ? "Begin"
                        : null}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="assessments-status">
              <h2>ASSESSMENTS COMING SOON:</h2>
              <ul className="coming-soon">
                {sectionsWithStatus
                  .filter(
                    (section) =>
                      !availableSections.includes(section.name.toLowerCase())
                  )
                  .map((section) => (
                    <li
                      key={section.number}
                      className={`assessment-item ${
                        section.status
                          ? section.status.toLowerCase()
                          : "pending"
                      }`}
                    >
                      <div>
                        <CircleIcon sx={{ color: "#e9eaeb" }} fontSize="large" />
                        <div className="section-name">{section.name}</div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="assessments-status">
              <h2>NOTIFICATION:</h2>
              <ul>
                <Form className="d-flex flex-column form-gap mx-auto">
                  <div className="d-lg-flex flex-lg-row justify-content-lg-between align-items-lg-center">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          checked={notifyWhenNewAssessment}
                          onClick={handleNotificationReminder}
                          {...register("notify")}
                          type="checkbox"
                          label="Notify me when new assessments are available"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </ul>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Assessment Restart</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Restarting your asessments will delete all your answers to this
            intangible assessment. This can not be reversed. Are you sure you
            want to go ahead and restart your assessments?
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between justify-content-sm-end">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={restartAssessment}>
              Restart
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showResendModal}
          onHide={handleCloseResendModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Hyfen8 Report Sent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            A copy of your Hyfen8 report has been successfully sent to your inbox.
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between justify-content-sm-end">
            <Button variant="primary" onClick={handleCloseResendModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
