import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react'
import { Button, Form } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { ApiClient, isApiError } from '../ApiClient';
import { useAuthenticationRedirect, useHotJarStateChange } from '../CustomHooks';

export default function ForgotPassword() {
  useAuthenticationRedirect(false);
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [isActionSuccessful, setIsActionSuccessful] = useState<boolean>(false);
  const [serverErrorMessage, setServerErrorMessage] = useState<string>();
  useHotJarStateChange();

  useEffect(() => {
    document.title = 'Forgot password';
  }, [])

  const forgotPasswordSchema = Joi.object().keys({
    email: Joi.string().required().email({ tlds: { allow: false } }).messages({
      'string.email': "Invalid email address",
      'string.empty': "Required",
    }),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: joiResolver(forgotPasswordSchema)
  });
  const onSubmit = async (data: { [x: string]: any; }) => {
    setIsSigningIn(true);
    const client = new ApiClient();
    const result = await client.sendVerificationEmail(data.email);
    if (isApiError(result) && result.code !== 404) {
      setServerErrorMessage(result.message);
      setIsSigningIn(false);
      return;
    }
    setServerErrorMessage(undefined);
    setIsActionSuccessful(true);
    setIsSigningIn(false);
  };
  return (
    <div>
      <div className="forgot-pass-page">
        <div className="container">
          <h1 className="text-1 mx-auto">Forgot Password</h1>
          <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column form-gap mx-auto">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="text-2">Username</Form.Label>
              <Form.Control {...register("email")}
                placeholder="Your email is your username" />
              <div className="error-text">{errors.email?.message}</div>
            </Form.Group>
            {serverErrorMessage ? <div className="error-text">{serverErrorMessage}</div> : ""}
            {isActionSuccessful ? <div className="mb-3">
              An email will be sent to your registered email address with instructions on how to reset your password.
              Haven't received an email from us? Please check your spam folder, or click on the button below to resend.
            </div> : ""}
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit"> {isSigningIn || isActionSuccessful ? "Resend" : "Continue"} </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
