import React, { useEffect, useState } from "react";
import { Button, Modal, Pagination, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Answer,
  ApiClient,
  isApiError,
  QueryUserResponse,
  SectionAnswerModel,
  SectionAnswerStatus,
} from "../ApiClient";
import { SECTIONS } from "../Constants";
import { useAuthenticationRedirect, useHotJarStateChange } from "../CustomHooks";
import { getLoggedInUser, redirectToHome } from "../utils";

const sectionCount = SECTIONS.length;
const pageLimit = 10;

const mimeTypeToFileExt: { [x: string]: string } = {
  "application/pdf": "pdf",
  "text/csv": "csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
};

const surveyState = (sectionAnswers: SectionAnswerModel[]) => {
  const completedSections = sectionAnswers.filter(
    (answers) => answers.status === SectionAnswerStatus.Complete
  ).length;
  return completedSections;
};

export default function Admin() {
  const navigate = useNavigate();
  useEffect(() => {
    const user = getLoggedInUser();
    if (user && user.role !== "admin") {
      redirectToHome(navigate);
    }
  }, [navigate]);
  useAuthenticationRedirect();
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState<QueryUserResponse>();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deleteProfileId, setDeleteProfileId] = useState<string | undefined>();
  useHotJarStateChange();

  useEffect(() => {
    (async () => {
      await loadUsers(currentPage);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUsers = async (pageNo: number) => {
    const client = new ApiClient();
    const result = await client.getUsers(pageLimit, pageNo);
    if (isApiError(result)) {
      console.error(result.message);
      return;
    }
    setUsers(result);
  };

  const handleSurveyDownload = async (
    id: string,
    email: string
  ): Promise<void> => {
    const client = new ApiClient();
    const result = await client.downloadSurveyReport(id);
    if (isApiError(result)) {
      console.error(result);
      return;
    }
    const url = window.URL.createObjectURL(result);
    const a = document.createElement("a");
    a.href = url;
    a.download = `hyfen8-completed-survey-${email}.xlsx`;
    // we need to append the element to the dom -> otherwise it will not work in firefox
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleProfileFileDownload = async (
    id: string,
    email: string
  ): Promise<void> => {
    const client = new ApiClient();
    const result = await client.downloadPofileFile(id);
    if (isApiError(result)) {
      console.error(result);
      return;
    }
    const fileExt = mimeTypeToFileExt[result.type] || "txt";
    const url = window.URL.createObjectURL(result);
    const a = document.createElement("a");
    a.href = url;
    a.download = `hyfen8-financial-statement-${email}.${fileExt}`;
    // we need to append the element to the dom -> otherwise it will not work in firefox
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleShowProfileDeleteModal = async (userId: string) => {
    setShowConfirmDeleteModal(true);
    setDeleteProfileId(userId);
  };

  const handleProfileDelete = async () => {
    if (!deleteProfileId) {
      return;
    }
    const client = new ApiClient();
    const result = await client.deleteUser(deleteProfileId);
    if (isApiError(result)) {
      console.error(result);
      return;
    }
    if (!users) {
      return;
    }
    const userResults = users.results.filter(
      (user) => user.id !== deleteProfileId
    );
    setUsers({ ...users, results: userResults });
    setDeleteProfileId(undefined);
    setShowConfirmDeleteModal(false);
  };

  const handlePageChange = async (pageNo: number) => {
    if (pageNo === currentPage) {
      return;
    }
    await loadUsers(pageNo);
    setCurrentPage(pageNo);
  };

  const isProfileFileUploaded = (answers: Answer[]) => {
    const finStatementFileAnswer = answers.find(
      (ans) => ans.name === "financial-statement-file"
    );
    return !!finStatementFileAnswer?.answer;
  };

  return (
    <div className="admin-page">
      <div className="container">
        <h1 id="admin-title" className="text-1 pb-3">
          Admin
        </h1>
        {users && (
          <div>
            <div className="admin-table">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Email</th>
                    <th>Progress</th>
                    <th>Download</th>
                    <th>File</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {users.results.map((user, index) => (
                    <tr key={user.id}>
                      <td>{(currentPage - 1) * pageLimit + index + 1}</td>
                      <td>{user.firstName}</td>
                      <td>{user.email}</td>
                      <td>{`${surveyState(
                        user.sectionAnswers
                      )}/${sectionCount}`}</td>
                      <td>
                        {surveyState(user.sectionAnswers) === sectionCount && (
                          <Button
                            variant="link"
                            onClick={() =>
                              handleSurveyDownload(user.id, user.email)
                            }
                          >
                            Download Survey
                          </Button>
                        )}
                      </td>
                      <td>
                        {isProfileFileUploaded(user.answers) && (
                          <Button
                            variant="link"
                            onClick={() =>
                              handleProfileFileDownload(user.id, user.email)
                            }
                          >
                            Download File
                          </Button>
                        )}
                      </td>
                      <td>
                        <Button
                          variant="outline-danger"
                          onClick={() => handleShowProfileDeleteModal(user.id)}
                        >
                          ❌
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Pagination>
              {Array(users.totalPages)
                .fill(1)
                .map((_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i + 1 === currentPage}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
            </Pagination>
          </div>
        )}
      </div>
      <Modal
        show={showConfirmDeleteModal}
        onHide={() => setShowConfirmDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this profile?</Modal.Body>
        <Modal.Footer className="d-flex justify-content-between justify-content-sm-end">
          <Button
            variant="secondary"
            onClick={() => setShowConfirmDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleProfileDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
