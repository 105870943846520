import { useEffect, useState } from 'react'
import { Control, FieldValues, UseFormRegister, useWatch } from 'react-hook-form'
import { QuestionModel, ConditionalQuestionModel } from '../../ApiClient'

import RenderQuestionnaireSectionField from './RenderQuestionnaireSectionField';


type SectionQuestionProps = {
    subSectionNumber: number,
    question: QuestionModel,
    control: Control<FieldValues, object>,
    register: UseFormRegister<FieldValues>,
    error?: [x: string],
    disabled: boolean
    answer: any
}

export default function SectionQuestion({ subSectionNumber, question, control, register, error, disabled, answer }: SectionQuestionProps) {

    const [questionId] = useState(`${subSectionNumber}.${question.id}`);
    const questionValue = useWatch({ control, name: questionId, defaultValue:answer});
    const [conditionalQuestions, setConditionalQuestions] = useState<ConditionalQuestionModel[]>([])
    useEffect(() => {
        if (!question.conditionalQuestions) {
            setConditionalQuestions([]);
            return;
        }
        // Workaround to handle an issue with conditional questions
        // When changing the value of the parent question.
        // The relevant conditional questions are not rendered.
        // There seems to be a second update to `questionValue` which sets its value to undefined.
        // This hack is to discard that 2nd update.
        // Ideally though we need to figure out what triggers the second update
        if (questionValue == null) {
            return;
        }
        setConditionalQuestions(question.conditionalQuestions.filter((condQuestion) =>
            condQuestion.conditionalValue === questionValue
        ));
    }, [question.conditionalQuestions, questionValue]);
    return (
        <div className='section-question'>
            <RenderQuestionnaireSectionField
                key={question.id}
                control={control}
                error={error?.[question.id as any] ?? undefined}
                questionKey={questionId} 
                questionValue={question}
                register={register}
                disabled = {disabled} 
                answer={answer}
                />
            {conditionalQuestions.map((condQuestion) => (
                <RenderQuestionnaireSectionField
                    key={condQuestion.id}
                    control={control}
                    error={error?.[condQuestion.id as any] ?? undefined}
                    questionKey={`${subSectionNumber}.${condQuestion.id}`}
                    questionValue={condQuestion} 
                    register={register}
                    disabled = {disabled} 
                    answer={answer}/>
            ))}
        </div>
    )
}
