import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { isAuthenticated, redirectToHome, redirectToLogin } from '../utils';

export default function useAuthenticationRedirect(authRequired: boolean = true) {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const authenticated = await isAuthenticated()
      if (authRequired) {
        if (authenticated) {
          return;
        }
        redirectToLogin(navigate);
      }
      else {
        if (authenticated) {
          redirectToHome(navigate);
        }
      }
    })();
  }, [authRequired, navigate]);
}